import { useQuery } from "@tanstack/react-query";
import apiLeaveApprovalCount from "../../../../services/manager/leaveRequest/apiLeaveApprovalCount";

export default function LeaveApprovalCountQuery() {
  const {
    data,
    isLoading: leaveApprovalCountLoading,
    refetch: leaveApprovalCountRefetch,
  } = useQuery({
    queryKey: ["leaveApprovalCount"],
    queryFn: () => apiLeaveApprovalCount(),
  });

  const leaveApprovalCount = data?.data.total_leave_count;

  return {
    leaveApprovalCount,
    leaveApprovalCountLoading,
    leaveApprovalCountRefetch,
  };
}
