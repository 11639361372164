import Api from "../axios";

export default async function apiInTimeReport(
  startDate,
  endDate,
  currentEmployeeId
) {
  return await Api.get(
    `/late_coming_list?start_date=${startDate}&end_date=${endDate}&employee_id=${currentEmployeeId}`
  );
}
