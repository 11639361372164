import { Box, InputAdornment, Stack, Typography } from "@mui/material";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Logo from "../../styles/svg/sofmenLogo.svg";
import { localStorageInstance } from "../../utils/localStorage";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { postLogin } from "../../services/auth/apiLogin";
import { LoadingButton } from "@mui/lab";
import styles from "./styles.module.css";
import PersonIcon from "@mui/icons-material/Person";
import lockIcon from "../../styles/svg/lockIcon.svg";
import { useResponsive } from "../../hooks/customUseResponsive";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import RHFTextField from "../../components/RFH/RFHtextfield";
import { getUserDetails } from "../../services/profile/apiShowData";

const login = Yup.object().shape({
  username: Yup.string().required("Please enter username."),
  password: Yup.string().required("Please enter password."),
});

function Login() {
  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(login),
    defaultValues: {},
  });
  const isMobile = useResponsive("down", "sm");

  const { mutate, isPending } = useMutation({
    mutationFn: postLogin,
    onSuccess: async (data) => {
      // console.log("login data", data);
      localStorageInstance.setItem({ key: "token", value: data.data.token });
      localStorageInstance.setItem({ key: "role", value: data.data.role });
      localStorageInstance.setItem({ key: "permission", value: data.data.permission });
      if (data.data.token) {
        try {
          const userDetails = await getUserDetails();
          localStorageInstance.setItem({
            key: "user",
            value: userDetails.data[0],
          });
          data.data.role === "admin"
            ? navigate("/manage")
            : navigate("/dashboard");
        } catch (err) {
          console.error("error in show user data", err);
          toast.error(err.message);
        }
      } else {
        toast.error(data.data.message);
      }
    },
    onError: (data) => {
      // console.log(data.response.data);
      if (data.response.data.message === "Unauthorized") {
        toast.error("Invalid credentials");
      }
    },
  });

  const submit = (data) => {
    /**
     * Login User
     */
    mutate({ passWord: data.password, userName: data.username });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)}>
        <Stack component="main" className={styles.mainContainer}>
          <Box className={styles.leftSideSection}>
            <Stack className={styles.logoCont}>
              <img src={Logo} alt="not found" className={styles.logo} />
              <Typography className={styles.txtBelowLogo}>
                Sofmen Applicant Management System
              </Typography>
            </Stack>
          </Box>

          <Box className={styles.rightSideSection}>
            <Stack className={styles.formCont}>
              <Stack className={styles.mobileLogoCont}>
                <img src={Logo} alt="not found" className={styles.mobileLogo} />
              </Stack>
              <Typography className={styles.loginTxt}>Login</Typography>
              <Typography className={styles.loginContent}>
                Please login with your LDAP Username and Password.
              </Typography>

              <RHFTextField
                margin="normal"
                fullWidth
                id="email"
                name="username"
                inputProps={{ maxLength: 40 }}
                placeholder="Username"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      className={styles.inputAdornment}
                      position="start"
                    >
                      <PersonIcon className={styles.icon} />
                    </InputAdornment>
                  ),
                }}
                autoComplete="email"
                autoFocus
                sx={{
                  "& .MuiInputBase-input": {
                    padding: isMobile ? "8px !important" : "",
                    paddingLeft: isMobile
                      ? "2px !important"
                      : "10px !important",
                  },
                }}
                className={styles.textField}
              />
              <RHFTextField
                margin="normal"
                fullWidth
                inputProps={{ maxLength: 40 }}
                name="password"
                placeholder="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      className={styles.inputAdornment}
                      position="start"
                    >
                      <img
                        src={lockIcon}
                        alt="lock"
                        className={styles.lockIcon}
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiInputBase-input": {
                    padding: isMobile ? "8px !important" : "",
                    paddingLeft: isMobile
                      ? "2px !important"
                      : "10px !important",
                  },
                }}
                className={styles.textField}
              />
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                className={styles.loginBtn}
                sx={{ mt: "14px", padding: "13px 16px" }}
                loading={isPending}
              >
                Login
              </LoadingButton>
            </Stack>
          </Box>
        </Stack>
      </form>
    </FormProvider>
  );
}

export default Login;
