import { useState } from "react";
import styles from "./styles.module.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TableHeader from "../../../components/TableHeader/TableHeader";
import LeaveStatement from "./LeaveStatement/LeaveStatement";
import LeaveLog from "./LeaveLog/LeaveLog";
import AddLeaveModal from "./AddLeaveModal/AddLeaveModal";

export default function LeaveManagement({ employeeId }) {
  const [value, setValue] = useState("leaveStatement");
  const [addleaveModal, setAddleaveModal] = useState(false);

  const handleChange = (event, value) => {
    setValue(value);
  };
  // console.log("LeaveManagement current employee", employeeId);

  return (
    <>
      <Box className={styles.mainContainer}>
        <Box className={styles.border}></Box>

        <TableHeader
          heading={"Leave Management"}
          btnicon={"ic:outline-plus"}
          handleModal={() => setAddleaveModal(true)}
        />
        <Box className={styles.tabContainer}>
          <Box>
            <Tabs
              aria-label="basic tabs example"
              value={value}
              onChange={handleChange}
              className={styles.tabCont}
            >
              <Tab
                label="Leave Statement"
                className={styles.tabHead}
                value="leaveStatement"
              />
              <Tab
                label="Leave Logs"
                className={styles.tabHead}
                value="leaveLogs"
              />
            </Tabs>
          </Box>
        </Box>
        <Box>
          {value === "leaveStatement" ? (
            <LeaveStatement employeeId={employeeId} showCheckbox={true} />
          ) : (
            <LeaveLog employeeId={employeeId} />
          )}
        </Box>
        <AddLeaveModal
          openProp={addleaveModal}
          handleClose={() => setAddleaveModal(false)}
          employeeId={employeeId}
        />
      </Box>
    </>
  );
}
