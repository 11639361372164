import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import styles from "./styles.module.css";
import DataGridComp from "../../../components/DataGrid/DataGrid";
import CompOffRequestQuery from "../../../utils/reactQuery/manager/compoOff/CompOffRequest";
import rejectIcon from "../../../styles/icons/crossIcon.svg";
import toast from "react-hot-toast";
import { useMutation } from "@tanstack/react-query";
import { postDeleteCompoffRequest } from "../../../services/manager/compOff/apiRejectCompoffRequest";
import AddCompOffModal from "./AddCompOff";
import { EmployeeUnderManagerQuery } from "../../../utils/reactQuery/manager/EmployeeUnderManager";
import TableHeader from "../../../components/TableHeader/TableHeader";
import Loader from "../../../components/Loader";

export default function CompOffRequest() {
  const { compOffList, isLoading, refetch } = CompOffRequestQuery();
  const [addCompOffModal, setAddCompOffModal] = useState(false);

  const { employeeList } = EmployeeUnderManagerQuery();

  const { mutate: mutateRejectRequest } = useMutation({
    mutationFn: postDeleteCompoffRequest,
    onSuccess: (data) => {
      toast.success(data.data.message);
      refetch();
    },
    onError: (err) => {
      // console.log("error employee feedback", err);
      toast.error(err?.response?.data?.message);
    },
  });

  const handleRejectRequest = (id) => {
    mutateRejectRequest({ compoff_id: id });
  };

  // console.log("Compoff selectedId", selectedId);

  const columns = [
    {
      field: "col1",
      headerName: "Employee Name",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col2",
      headerName: "Date",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col3",
      headerName: "Duration",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col4",
      headerName: "Description",
      minWidth: 120,
      flex: 3,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col5",
      headerName: "Action",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => (
        <Stack className={styles.iconCont}>
          <img
            onClick={() => handleRejectRequest(value.value.compoff_id)}
            className={styles.icon}
            src={rejectIcon}
            alt="delete"
          />
        </Stack>
      ),
    },
  ];

  return (
    <Box className={styles.mainContainer}>
      <TableHeader
        heading="Compensatory Off"
        btnicon="ic:outline-plus"
        handleModal={() => setAddCompOffModal(true)}
      />

      <Box className={styles.datagridCont}>
        {isLoading && (
          <Stack className={styles.loaderCont}>
            <Loader />
          </Stack>
        )}
        <DataGridComp
          columns={columns}
          initialRows={compOffList || []}
          pageSize={50}
        />
      </Box>

      {addCompOffModal && (
        <AddCompOffModal
          openProp={addCompOffModal}
          handleClose={() => {
            setAddCompOffModal(false);
          }}
          listOfEmp={employeeList}
          refetch={refetch}
        />
      )}
    </Box>
  );
}
