import { useRef, useState } from "react";
import { formatDate } from "../../utils/helpers";
import { useResponsive } from "../../hooks/customUseResponsive";
import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { leaveLogSchema as inTimeReportSchema } from "../../schemas/leaveLog";
import { Box, Stack, Typography } from "@mui/material";
import DatePickerComponent from "../DatePickerComp/DatePickerComp";
import { LoadingButton } from "@mui/lab";
import Loader from "../Loader";
import DataGridComp from "../DataGrid/DataGrid";
import styles from "./styles.module.css";
import InTimeReportQuery from "../../utils/reactQuery/attendance/InTimeReport";
import approvedIcon from "../../styles/icons/approvedIcon.svg";
import ConfirmModal from "../ConfirmationModal/ConfirmModal";
import { useMutation } from "@tanstack/react-query";
import apiApproveLateComing from "../../services/attendance/apiApproveLateComing";
import toast from "react-hot-toast";

export default function InTime({ currentEmployeeId }) {
  const date = new Date();
  const isMobile = useResponsive("down", "sm");
  const [isGenerating, setIsGenerating] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  // Calculate start and end dates
  const startDate = useRef(
    formatDate(date) // Today's date
  );
  const endDate = useRef(formatDate(date)); // Today's date

  const { report, isLoading, refetch } = InTimeReportQuery(
    startDate.current,
    endDate.current,
    currentEmployeeId
  );

  const { mutate: mutateApproval } = useMutation({
    mutationFn: apiApproveLateComing,
    onSuccess: (data) => {
      toast.success(data.data.message);
      refetch();
    },
    onError: (data) => {
      console.error("error approving late coming", data);
      toast.error(data.response.data.error);
    },
    onSettled: () => {
      setApproveModal(false);
    },
  });

  const handleApproval = (applicationId) => {
    setSelectedId(applicationId);
    setApproveModal(true);
  };

  const confirmApproval = () => {
    mutateApproval(selectedId);
  };

  const columns = [
    {
      field: "col1",
      headerName: "Employee Name",
      minWidth: isMobile ? 150 : 200,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col2",
      headerName: "Date",
      minWidth: isMobile ? 150 : 200,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col3",
      headerName: "In Time",
      minWidth: isMobile ? 150 : 200,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value.time}</span>,
      cellClassName: (value) => (value.value.isLate ? styles.late : null),
    },
    {
      field: "col4",
      headerName: "Action",
      minWidth: isMobile ? 150 : 200,
      flex: 0.5,
      sortable: false,
      resizable: false,
      renderCell: (value) =>
        value.value ? (
          <Stack className={styles.iconsCont}>
            <img
              className={styles.icon}
              src={approvedIcon}
              alt="view"
              onClick={() => handleApproval(value.value)}
            />
          </Stack>
        ) : null,
    },
  ];

  const defaultValues = {
    startDate: dayjs(startDate.current),
    endDate: dayjs(endDate.current),
  };

  const methods = useForm({
    resolver: yupResolver(inTimeReportSchema),
    defaultValues,
  });

  const onSubmit = async (formData) => {
    setIsGenerating(true);

    const start_date = formatDate(formData.startDate);
    const end_date = formatDate(formData.endDate);

    startDate.current = start_date;
    endDate.current = end_date;

    setIsGenerating(false);
  };

  return (
    <Box>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack direction="row" className={styles.formContainer}>
            <Stack className={styles.dateSection}>
              <Typography className={styles.datePickerlabel}>
                From Date
              </Typography>

              <DatePickerComponent name="startDate" />
            </Stack>

            <Stack className={styles.dateSection}>
              <Typography className={styles.datePickerlabel}>
                To Date
              </Typography>
              <DatePickerComponent name="endDate" />
            </Stack>

            <LoadingButton
              loading={isGenerating}
              type="submit"
              variant="contained"
              className={styles.generateBtn}
            >
              Generate Report
            </LoadingButton>
          </Stack>
        </form>
      </FormProvider>
      <Box className={styles.dataGridContainer}>
        {isLoading && (
          <Stack className={styles.loaderCont}>
            <Loader />
          </Stack>
        )}
        <DataGridComp
          columns={columns || []}
          initialRows={report || []}
          footer={false}
          pageSize={50}
        />
      </Box>
      {approveModal && (
        <ConfirmModal
          openProp={approveModal}
          handleClose={() => setApproveModal(false)}
          handleConfirm={() => confirmApproval()}
          tabHeader="Approve Request"
          content="Do you want to proceed with approving this request?"
          btnText="approve"
        />
      )}
    </Box>
  );
}
