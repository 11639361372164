import { useQuery } from "@tanstack/react-query";
import apiLeaveApprovalList from "../../../../services/manager/leaveRequest/apiLeaveApprovalList";
import { usDateFormate } from "../../../../hooks/dateFormat";

export default function LeaveApprovalListQuery() {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["leaveApprovalList"],
    queryFn: () => apiLeaveApprovalList(),
  });

  const leaveApprovalList = data?.data.leave_approval_list.map((ele, idx) => ({
    id: idx,
    col1: ele.employee_name,
    col2: usDateFormate(ele.date),
    col3: ele.total_days,
    col4: ele.application_type,
    col5: usDateFormate(ele.applied_on),
    col6: ele.status,
    col7: {
      id: ele.application_id,
      status: ele.status,
    },
  }));

  return { leaveApprovalList, isLoading, refetch };
}
