import { Box } from "@mui/material";
import CardSection from "../CardSection/CardSection";
import styles from "./styles.module.css";
import TableHeader from "../../../components/TableHeader/TableHeader";
import { useState } from "react";
import OnBoard from "../../../components/OnBoard/OnBoard";
import AllEmployees from "./AllEmployees";
import { isAdmin, isManager } from "../../../utils/helpers";
import EmployeePerformanceFeedback from "../EmployeesPerformanceFeedback/EmployeesPerformanceFeedback";
import LeaveRequests from "../LeaveRequests.js/LeaveRequests";
import ManagerEmployees from "./ManagerEmployees";
import AttendanceRequest from "../AttendanceRequest.js/AttendanceRequest";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

export default function TeamDashboard() {
  const [onBoard, setOnBoard] = useState(false);

  const handleModalClose = () => {
    setOnBoard(!onBoard);
  };

  return (
    <>
      {isAdmin() && (
        <>
        <CardSection />
          <Box className={styles.mainContainer}>
            <TableHeader
              heading="All Employees"
              btntxt="Onboard Employee"
              handleModal={handleModalClose}
            />
            <AllEmployees />
          </Box>
        </>
      )}
      {isManager() && (
        <>
          <ManagerEmployees />
          <AttendanceRequest />
          <LeaveRequests />
          <EmployeePerformanceFeedback employeeId={null} />
        </>
      )}
      <OnBoard
        openProp={onBoard}
        handleClose={handleModalClose}
        title="OnBoard Emlpoyee"
      />
    </>
  );
}
