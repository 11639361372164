import { useQuery } from "@tanstack/react-query";
import apiAttendanceApprovalList from "../../../../services/manager/attendance/apiAttendanceApprovalList";
import { usDateFormate } from "../../../../hooks/dateFormat";

export default function AttendanceApprovalListQuery() {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["attendanceApprovalList"],
    queryFn: () => apiAttendanceApprovalList(),
  });

  const attendanceApprovalList = data?.data.attendance_approval_list.map(
    (ele, idx) => ({
      id: idx,
      col1: ele.employee_name,
      col2: usDateFormate(ele.date),
      col3: ele.application_type,
      col4: ele.in_time,
      col5: ele.out_time,
      col6: usDateFormate(ele.applied_on),
      col7: ele.status,
      col8: {
        id: ele.application_id,
        status: ele.status,
      },
    })
  );

  return { attendanceApprovalList, isLoading, refetch };
}
