import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { localStorageInstance } from "../../utils/localStorage";
import { isManager, isUser } from "../../utils/helpers";
import ForbiddenView from "../../pages/Forbidden";
import Api from "../../services/axios";
import FullscreenLoader from "../../components/FullscreenLoader";

export default function Private() {
  const [isValid, setIsValid] = useState(null);

  useEffect(() => {
    const validateToken = async () => {
      const token = localStorageInstance.getItem("token");

      if (!token) {
        setIsValid(false);
        return;
      }

      try {
        const {
          data: { isActiveSession },
        } = await Api.get("/valid_session");
        // console.log("valid_session", isActiveSession);

        if (!isActiveSession) {
          localStorageInstance.clear();
          setIsValid(false);
          return;
        }

        setIsValid(true);
      } catch (error) {
        console.error("Invalid token:", error);
        localStorageInstance.clear();
        setIsValid(false);
      }
    };

    validateToken();
  }, []);

  // Show a loading state until validation is complete
  if (isValid === null) return <FullscreenLoader />;

  return isValid ? <Outlet /> : <Navigate to="/" />;
}

export function ForbidUserAccess({ children }) {
  return isUser() ? <ForbiddenView /> : children;
}

export function ForbidManagerUserAccess({ children }) {
  return isUser() || isManager() ? <ForbiddenView /> : children;
}
