import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import styles from "./styles.module.css";
import Default from "../../components/Avatar";
import sofmenLogo from "../../styles/svg/sofmenLogo.svg";
import AccountPopOver from "../../components/AccountPopover/AccountPopOver";
import ConfirmModal from "../../components/ConfirmationModal/ConfirmModal";
import { useEffect, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { localStorageInstance } from "../../utils/localStorage";
import CssBaseline from "@mui/material/CssBaseline";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { useQueryClient } from "@tanstack/react-query";
import { ShowUserData } from "../../utils/reactQuery/ShowUserData";
import { useResponsive } from "../../hooks/customUseResponsive";
import NewDashboardIcon from "../../styles/icons/NewDashboardIcon.svg";
import NewTeamDashboardIcon from "../../styles/icons/employeeDetailIcon.svg";
import NewCompanyStuffIcon from "../../styles/icons/NewCompanyStuffIcon.svg";
import CompensatoryOffIcon from "../../styles/icons/upcomingLeave.svg";
import NewRunJobIcon from "../../styles/icons/NewRunJobIcon.svg";
import AddAttendanceIcon from "../../styles/icons/addAttendanceIcon.svg";
import { Backdrop, Button, Collapse, Typography } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import OnBoard from "../../components/OnBoard/OnBoard";
import { isAdmin, isManager } from "../../utils/helpers";
import Navbar from "../../components/Navbar/Navbar";
import Loader from "../../components/Loader";
import toast from "react-hot-toast";
import apiRunJob from "../../services/manager/apiRunJob";

const drawerWidth = 240;

const openedMixin = (theme, isMobile) => ({
  width: drawerWidth,
  overflowX: "hidden",
  visibility: "visible !important",
  transform: isMobile && "translateX(0) !important",
});

const closedMixin = (theme) => ({
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 5px)`,
  },
});

const DrawerHeader = styled("div")(({ theme, isMobile }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  height: isMobile ? "58px" : "66px",
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, isMobile }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...(open && {
    ...openedMixin(theme, isMobile),
    "& .MuiDrawer-paper": openedMixin(theme, isMobile),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const listItems = [
  { content: "Dashboard", icon: NewDashboardIcon, path: "/dashboard" },
  {
    content: "Team Dashboard",
    icon: NewTeamDashboardIcon,
    path: "/manage",
  },
  {
    content: "Company Stuff",
    icon: NewCompanyStuffIcon,
    path: "/manage/company-stuff",
    children: [
      {
        content: "Holiday List",
        icon: NewCompanyStuffIcon,
        path: "/manage/company-stuff#holidays",
      },
      {
        content: "Policies",
        icon: NewCompanyStuffIcon,
        path: "/manage/company-stuff#policies",
      },
      {
        content: "Notices",
        icon: NewCompanyStuffIcon,
        path: "/manage/company-stuff#notices",
      },
    ],
  },
  {
    content: "Reports",
    icon: NewCompanyStuffIcon,
    path: "/manage/reports/leave-statement",
    children: [
      {
        content: "Leave Statement",
        icon: NewCompanyStuffIcon,
        path: "/manage/reports/leave-statement",
      },
      {
        content: "Attendance Register",
        icon: NewCompanyStuffIcon,
        path: "/manage/reports/attendance-register",
      },
      {
        content: "Summary Report",
        icon: NewCompanyStuffIcon,
        path: "/manage/reports/summary-report",
      },
      {
        content: "In Time Report",
        icon: NewCompanyStuffIcon,
        path: "/manage/reports/intime-report",
      },
      {
        content: "Early Leaving Report",
        icon: NewCompanyStuffIcon,
        path: "/manage/reports/earlyleaving-report",
      },
    ],
  },
  {
    content: "Add Attendance",
    icon: AddAttendanceIcon,
    path: "/manage/add-attendance",
  },
  {
    content: "Compensatory Off",
    icon: CompensatoryOffIcon,
    path: "/manage/comp-off",
  },
];

// console.log("user role", localStorageInstance.getItem("role"));

export default function ManageTeam() {
  const theme = useTheme();
  const isMobile = useResponsive("down", "sm");
  const [open, setOpen] = useState(isMobile ? false : true);
  const [openAcc, setOpenAcc] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const queryClient = useQueryClient();
  const { user } = ShowUserData();
  const [loading, setLoading] = useState(false);

  const handleButtonClick = async () => {
    setLoading(true);

    try {
      const response = await apiRunJob();
      // console.log("response apiRunJob", response.data);
      toast.success(response.data.message);
    } catch (error) {
      console.error(`Error in job run`, error);
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleLogOut = () => {
    localStorageInstance.clear();
    queryClient.clear();
    navigate("/");
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [expanded, setExpanded] = useState({});
  const location = useLocation();

  const isActiveLink = (path) => {
    // console.log("location", location);
    // console.log("path", path);
    // console.log("isActiveLink", location.pathname === path);
    return location.pathname === path;
  };

  const handleExpand = (index) => {
    setExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  useEffect(() => {
    // Scroll to the element with the id from the hash in the URL
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1)); // Remove the '#' from the hash
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      {isManager() && <Navbar />}
      <Box
        className={styles.mainContainer}
        sx={{ marginLeft: isAdmin() ? 30 : 12 }}
      >
        {isAdmin() && (
          <>
            <CssBaseline />
            <AppBar position="fixed" open={open} className={styles.mainAppBar}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    marginRight: 5,
                    ...(open && { display: "none" }),
                  }}
                >
                  <MenuIcon sx={{ color: "#000" }} />
                </IconButton>
                <Box className={styles.logoCont}>
                  {open ? (
                    <IconButton
                      onClick={handleDrawerClose}
                      className={styles.leftIconCont}
                    >
                      {theme.direction === "rtl" ? (
                        <ChevronRightIcon />
                      ) : (
                        <ChevronLeftIcon />
                      )}
                    </IconButton>
                  ) : (
                    <img
                      className={styles.logo}
                      src={sofmenLogo}
                      alt="sofmen logo"
                    />
                  )}
                </Box>

                <Stack
                  direction="row"
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                >
                  <Box
                    onClick={() => setOpenAcc(true)}
                    sx={{ cursor: "pointer" }}
                  >
                    <Default name={user?.name} designation={user?.position} />
                  </Box>
                </Stack>

                <AccountPopOver
                  openpop={openAcc}
                  handleProfile={() => setProfileModal(true)}
                  handleClose={() => setOpenAcc(false)}
                  handleLogout={() => setLogoutModal(true)}
                  anchorEl={anchorEl}
                />
              </Toolbar>
            </AppBar>

            {/* Drawer */}
            <Drawer
              variant={isMobile ? "temporary" : "permanent"} // Update drawer type for mobile experience
              open={open}
              onClose={handleDrawerClose}
              isMobile={isMobile}
              ModalProps={{
                keepMounted: true, // Keeps drawer mounted on mobile for better performance
              }}
              // className={styles.sideBar}
              sx={{
                display: isMobile
                  ? { xs: "block", sm: "none" }
                  : { xs: "none", sm: "block" },
                "& .MuiDrawer-paper": {
                  border: "none !important",
                  backgroundColor: "#F1F2F7",
                },
              }}
            >
              <DrawerHeader
                isMobile={isMobile}
                sx={{
                  backgroundColor: "#FFF",
                  // minHeight: "65.5px",
                  minHeight: isMobile ? "56px" : "64px",
                  paddingBottom: "1px",
                  borderBottom: "2px solid",
                  paddingRight: "0px !important",
                }}
              >
                <img
                  src={sofmenLogo}
                  alt="sofmen logo"
                  className={styles.logoInDrawer}
                />
              </DrawerHeader>

              <List>
                {listItems.map((item, index) => (
                  <Box key={index}>
                    {/* Main Item */}
                    <ListItem
                      disablePadding
                      // className={open ? styles.listItem : styles.closedListItem}
                      className={styles.listItem}
                      sx={{
                        marginBottom: item.children && 0,
                      }}
                      onClick={() => item.children && handleExpand(index)}
                    >
                      <NavLink
                        to={item.path}
                        target={item.path === "/run-job" ? "_blank" : "_self"}
                        rel={
                          item.path === "/run-job" ? "noopener noreferrer" : ""
                        }
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          disableGutters
                          sx={{
                            minHeight: 45,
                            justifyContent: open ? "initial" : "center",
                            padding: "0px 3px 0px 16px",
                            backgroundColor: isActiveLink(item.path)
                              ? "#E5EAFF"
                              : "transparent",
                            "&:hover": {
                              backgroundColor: isActiveLink(item.path)
                                ? "#CBD4FF"
                                : "#CBD4FF",
                            },
                          }}
                        >
                          <Tooltip title={item.content}>
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 1 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              <img src={item.icon} alt="icon" />
                            </ListItemIcon>
                          </Tooltip>
                          <ListItemText
                            primary={item.content}
                            sx={{
                              // opacity: open ? 1 : 0,
                              display: open ? "block" : "none",
                              width: open ? 150 : "auto",
                            }}
                            className={styles.content}
                          />
                        </ListItemButton>
                      </NavLink>
                      {/* Show expand/collapse icon if item has children */}
                      {item.children &&
                        (expanded[index] ? (
                          <ExpandLess
                            sx={{
                              marginLeft: isMobile && -0.5,
                              marginRight: !isMobile && 1,
                            }}
                          />
                        ) : (
                          <ExpandMore
                            sx={{
                              marginLeft: isMobile && -0.5,
                              marginRight: !isMobile && 1,
                            }}
                          />
                        ))}
                    </ListItem>

                    {/* Child Items (rendered if expanded) */}
                    {item.children && (
                      <Collapse
                        in={expanded[index]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {item.children.map((child, childIndex) => (
                            <NavLink
                              to={child.path}
                              key={childIndex}
                              style={{ textDecoration: "none" }}
                            >
                              <ListItem
                                disablePadding
                                className={styles.childListItem}
                                sx={{
                                  paddingLeft: open ? 4 : 2,
                                  backgroundColor: isActiveLink(child.path)
                                    ? "#E5EAFF"
                                    : "transparent",
                                  "&:hover": {
                                    backgroundColor: isActiveLink(item.path)
                                      ? "#CBD4FF"
                                      : "#CBD4FF",
                                  },
                                }}
                              >
                                <ListItemButton>
                                  <ListItemIcon
                                    sx={{
                                      minWidth: 0,
                                      mr: open ? 1 : "auto",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <img src={child.icon} alt="icon" />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={child.content}
                                    className={styles.content}
                                    sx={{
                                      opacity: open ? 1 : 0,
                                    }}
                                  />
                                </ListItemButton>
                              </ListItem>
                            </NavLink>
                          ))}
                        </List>
                      </Collapse>
                    )}
                  </Box>
                ))}
              </List>
              <Box className={styles.runJobButtonContainer}>
                <Button
                  className={styles.runJobButton}
                  onClick={handleButtonClick}
                >
                  <Tooltip title="Run the Job">
                    <img
                      src={NewRunJobIcon}
                      alt="icon"
                      width={28}
                      height={28}
                    />
                  </Tooltip>
                  {open && (
                    <div className={styles.content}>
                      <Typography variant="caption">Run the Job</Typography>
                    </div>
                  )}
                </Button>
              </Box>
            </Drawer>
          </>
        )}

        <Backdrop open={loading} className={styles.backdrop}>
          <Loader />
        </Backdrop>

        {/* Main Content */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: isMobile ? 2 : 5,
            paddingTop: isManager() ? 2 : 16,
            boxSizing: "initial",
            minWidth: "300px",
            maxWidth: "1600px",
          }}
        >
          <Outlet />
        </Box>

        {/* Profile and Confirm Modals */}
        <OnBoard
          openProp={profileModal}
          handleClose={() => setProfileModal(false)}
          title={isAdmin() ? "Update Profile" : "View Profile"}
          currentEmployeeId={user?.employee_id}
        />
        {logoutModal && (
          <ConfirmModal
            openProp={logoutModal}
            handleClose={() => setLogoutModal(false)}
            handleConfirm={handleLogOut}
            tabHeader="Logout"
            heading="Are You Logging Out?"
            content="Are you sure you want to Logout?"
            btnText="log out"
          />
        )}
      </Box>
    </>
  );
}
