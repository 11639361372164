import { Box, MenuItem, Modal, Stack, Typography } from "@mui/material";
import styles from "./styles.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePickerComponent from "../../../../components/DatePickerComp/DatePickerComp";
import RHFTextField from "../../../../components/RFH/RFHtextfield.js";
import { RHFSelect } from "../../../../components/RFH/RFHselect.js";
import { addLeaveSchema } from "../../../../schemas/addLeaveSchema.js";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { useResponsive } from "../../../../hooks/customUseResponsive.js";
import { addEmployeeLeave } from "../../../../services/leave/apiAddLeaveApp.js";
import GetCompOffDateQuery from "../../../../utils/reactQuery/manager/compoOff/GetCompOffDates.js";
import Loader from "../../../../components/Loader.js";

export default function AddLeaveModal({
  openProp,
  handleClose,
  employeeId,
}) {
  const queryClient = useQueryClient();
  const isMobile = useResponsive("down", "sm");
  // console.log("AddLeaveModal employeeId", employeeId);

  const { availableCompOffs, isLoading } = GetCompOffDateQuery(employeeId);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? 330 : 440,
    bgcolor: "#F8F9FA",
    boxShadow: 24,
    borderRadius: "10px",
  };

  const defaultValues = {
    startTime: "first_half",
    endTime: "first_half",
    applicationType: "Casual",
    compoffDate: "",
    employeeId: employeeId,
  };

  const methods = useForm({
    resolver: yupResolver(addLeaveSchema),
    defaultValues,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: addEmployeeLeave,
    onSuccess: (data) => {
      toast.success(data.data.message);
      methods.reset();
      queryClient.invalidateQueries({
        queryKey: ["leaveRequest", employeeId],
      });
      queryClient.invalidateQueries({
        queryKey: ["leaveLogList"],
      });
      handleClose();
    },
    onError: (data) => {
      // console.log("error --- ", data.response.data.error);
      toast.error(data.response.data.error);
    },
  });

  const onSubmit = (formData) => {
    // console.log("formData === ", formData);
    mutate({ ...formData });
  };
  return (
    <Modal open={openProp} onClose={handleClose}>
      <Box sx={{ ...style }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="row" className={styles.header}>
              <Typography className={styles.heading}>Add Leave</Typography>
              <Box onClick={() => handleClose()}>
                <CloseIcon className={styles.icon} />
              </Box>
            </Stack>
            <Stack className={styles.formCont}>
              <Box>
                <Typography className={styles.label}>From</Typography>
                <Stack className={styles.inputFeild} direction="row">
                  <DatePickerComponent name="startDate" isFullWidth={true} />
                  <RHFSelect name="startTime" className={styles.selectComp}>
                    {[
                      <MenuItem value="first_half" key="1">
                        First Half
                      </MenuItem>,

                      <MenuItem value="second_half" key="2">
                        Second Half
                      </MenuItem>,
                    ]}
                  </RHFSelect>
                </Stack>
              </Box>

              <Box>
                <Typography className={styles.label}>To</Typography>
                <Stack className={styles.inputFeild} direction="row">
                  <DatePickerComponent name="endDate" isFullWidth={true} />
                  <RHFSelect className={styles.selectComp} name="endTime">
                    {[
                      <MenuItem
                        value="first_half"
                        key="1"
                        className={styles.menuItem}
                      >
                        First Half
                      </MenuItem>,

                      <MenuItem
                        value="second_half"
                        key="2"
                        className={styles.menuItem}
                      >
                        Second Half
                      </MenuItem>,
                    ]}
                  </RHFSelect>
                </Stack>
              </Box>

              <Box>
                <Typography className={styles.label}>Message</Typography>
                <RHFTextField
                  name="message"
                  multiline
                  rows={4}
                  fullWidth
                  className={styles.textarea}
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: "#fff !important",
                    },
                  }}
                />
              </Box>
              <Stack
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Box>
                  <Typography className={styles.label}>
                    Available Comp Offs
                  </Typography>
                  {isLoading && <Loader />}
                  {availableCompOffs?.map((compoff, idx) => (
                    <Typography key={idx} value={compoff.id}>
                      {compoff.date}&nbsp; ({compoff.day})&nbsp;
                      {compoff.type}
                    </Typography>
                  ))}
                </Box>
                <Box>
                  <LoadingButton
                    loading={isPending}
                    variant="contained"
                    type="submit"
                    className={styles.btn}
                  >
                    Apply
                  </LoadingButton>
                </Box>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
}
