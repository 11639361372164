import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import styles from "./styles.module.css";
import TableHeader from "../../../components/TableHeader/TableHeader";
import DataGridComp from "../../../components/DataGrid/DataGrid";
import viewIcon from "../../../styles/icons/viewicon.svg";
import editIcon from "../../../styles/icons/editIcon.svg";
import deleteIcon from "../../../styles/svg/deleteIcon.svg";
import { NoticesListQuery } from "../../../utils/reactQuery/notice/NoticeList";
import NotesPopup from "../../../components/NotesPopup/NotesPopup";
import { useResponsive } from "../../../hooks/customUseResponsive";
import { useMutation } from "@tanstack/react-query";
import { usDateFormate } from "../../../hooks/dateFormat";
import { postReadNotice } from "../../../services/notice/apiReadNotice";
import AddNoticeModal from "../../ManageTeam/AdminNotices/AddNotice";
import ConfirmModal from "../../../components/ConfirmationModal/ConfirmModal";
import UpdateNotice from "../../../components/NotesPopup/EditNotice";
import { deletePolicyNotice as deleteNoticeApi } from "../../../services/notice/apiDeletePolicyNotice";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import Loader from "../../../components/Loader";

export default function Notices() {
  const { noticeList, isLoading, refetch } = NoticesListQuery();
  const [notePopup, setNotePopup] = useState(false);
  const [noticeId, setNoticeId] = useState("");
  const [addNotice, setAddNotice] = useState(false);
  const [editNotice, setEditNotice] = useState(false);
  const [deleteNotice, setDeleteNotice] = useState(false);
  const isMobile = useResponsive("down", "sm");
  const location = useLocation();
  const isDashboard = location.pathname.includes("dashboard");

  const { mutate } = useMutation({
    mutationFn: postReadNotice,
    onSuccess: (data) => {
      refetch();
    },
    onError: (err) => {
      console.error("Error while updating the read status", err);
    },
  });

  const { mutate: deleteMutation } = useMutation({
    mutationFn: deleteNoticeApi,
    onSuccess: (data) => {
      toast.success(data.data.message);
      refetch();
    },
    onError: (err) => {
      console.error("Error while deleting the notice", err);
      toast.error("Something went wrong while deleting the notice.");
    },
    onSettled: () => {
      setDeleteNotice(false);
    },
  });

  const handleView = (id) => {
    setNoticeId(id);
    setNotePopup(true);
    mutate({ id });
  };

  const handleEdit = (id) => {
    setNoticeId(id);
    setEditNotice(true);
  };

  const handleDelete = (id) => {
    setNoticeId(id);
    setDeleteNotice(true);
  };

  const handleConfirm = (id) => {
    // console.log("confirm delete id", id);
    deleteMutation({ id });
  };

  const columns = [
    {
      field: "col1",
      headerName: "Subject",
      minWidth: isMobile ? 150 : 250,
      flex: 2,
      sortable: false,
      resizable: false,
      renderCell: (value) => {
        return (
          <Stack direction="row" className={styles.noticeSubject}>
            <Typography className={styles.title}>
              {value.formattedValue.name}
            </Typography>
            {value.formattedValue.isRead ? null : (
              <Box className={styles.greenBadge}></Box>
            )}
          </Stack>
        );
      },
    },
    {
      field: "col2",
      headerName: "Create Date",
      minWidth: isMobile ? 100 : 161,
      flex: 1.1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{usDateFormate(value.value)}</span>,
    },
    {
      field: "col3",
      headerName: "Actions",
      minWidth: isMobile ? 100 : 123,
      sortable: false,
      flex: 0.8,
      resizable: false,
      renderCell: (value) => {
        return (
          <Stack gap={1} className={styles.viewCont}>
            <Box
              component="img"
              src={viewIcon}
              alt="view"
              className={styles.icon}
              onClick={() => handleView(value.value)}
            />
            {!isDashboard && (
              <>
                <Box
                  component="img"
                  src={editIcon}
                  alt="edit"
                  className={styles.icon}
                  onClick={() => handleEdit(value.value)}
                />
                <Box
                  component="img"
                  src={deleteIcon}
                  alt="delete"
                  className={styles.icon}
                  onClick={() => handleDelete(value.value)}
                />
              </>
            )}
          </Stack>
        );
      },
    },
  ];

  const initialRows = noticeList
    ? noticeList.map((ele, index) => {
        return {
          id: index,
          col1: { name: ele.policy_name, isRead: ele.is_read_by_employee },
          col2: ele.date_added,
          col3: ele.policy_id,
        };
      })
    : [];

  return (
    <Box className={styles.mainContainer}>
      {/* For the scrolling of the sections */}
      <span id="notices"></span>
      <TableHeader
        heading="Notices"
        btnicon={!isDashboard && "ic:outline-plus"}
        handleModal={!isDashboard && (() => setAddNotice(true))}
      />
      <Box className={styles.datagridCont}>
        {isLoading && (
          <Stack
            className={
              isDashboard ? styles.dashboardLoaderCont : styles.loaderCont
            }
          >
            <Loader />
          </Stack>
        )}
        <DataGridComp columns={columns} initialRows={initialRows} />
      </Box>
      {notePopup && (
        <NotesPopup
          openPop={notePopup}
          handleClose={() => setNotePopup(false)}
          id={noticeId}
        />
      )}
      {addNotice && (
        <AddNoticeModal
          openProp={addNotice}
          handleClose={() => setAddNotice(false)}
          refetch={refetch}
        />
      )}
      {editNotice && (
        <UpdateNotice
          openProp={editNotice}
          handleClose={() => setEditNotice(false)}
          id={noticeId}
        />
      )}
      {deleteNotice && (
        <ConfirmModal
          openProp={deleteNotice}
          handleClose={() => setDeleteNotice(false)}
          handleConfirm={() => handleConfirm(noticeId)}
          tabHeader="Delete Notice"
          heading="Delete Notice ?"
          content="Are you sure you want to delete this notice? This action can not be undone."
          btnText="Delete"
        />
      )}
    </Box>
  );
}
