import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import styles from "./styles.module.css";
import Attendence from "../../Dashboard/Attendence/Attendence";
import Navbar from "../../../components/Navbar/Navbar";
import totalLeaveIcon from "../../../styles/icons/totalLeave.svg";
import lateDaysIcon from "../../../styles/icons/lateDays.svg";
import shortDaysIcon from "../../../styles/icons/lateComing.svg";
import { addZeroToSingleDigits } from "../../../utils/helpers";
import { LateComingQuery } from "../../../utils/reactQuery/LateComing";
import { PresentMonthLeaves } from "../../../utils/reactQuery/PresentMonthLeaves";
import { PreviousMonthLeavesQuery } from "../../../utils/reactQuery/PreviousMonthLeaves";
import { ShortDaysQuery } from "../../../utils/reactQuery/ShortDays";
import { Button } from "@mui/material";
import Iconify from "../../../components/Icon/Iconify";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import OnBoard from "../../../components/OnBoard/OnBoard";
import LeaveRequests from "../LeaveRequests.js/LeaveRequests";
import AttendanceRequest from "../AttendanceRequest.js/AttendanceRequest";
import EmployeePerformanceFeedback from "../EmployeesPerformanceFeedback/EmployeesPerformanceFeedback";
import { PersonalInfoQuery } from "../../../utils/reactQuery/profile/PersonalInfo";
import LeaveManagement from "../../Dashboard/LeaveManagement/LeaveManagement";

export default function ManageEmployee() {
  const location = useLocation();
  const employeeId = location.pathname.split("/").pop();
  // console.log("employeeId", employeeId);
  const { lateComing } = LateComingQuery(employeeId);
  const { presentMonthLeaves } = PresentMonthLeaves(employeeId);
  const { previousMonthLeaves } = PreviousMonthLeavesQuery(employeeId);
  const { shortDays } = ShortDaysQuery(employeeId);
  const { employee } = PersonalInfoQuery(employeeId);
  // console.log("ManageEmployee name", employee);

  const [openProfile, setOpenProfile] = useState(false);

  return (
    <>
      <Navbar />
      <Box className={styles.mainContainer}>
        <Stack className={styles.container} direction="row">
          <Typography className={styles.heading}>{employee?.name}</Typography>
          <Button
            className={styles.btn}
            variant="outlined"
            startIcon={<Iconify icon="mdi:edit-outline" />}
            onClick={() => setOpenProfile(true)}
          >
            Edit Info
          </Button>
        </Stack>
        <Stack direction="row" className={styles.cardWrapper}>
          <Stack className={styles.cardCont} direction="row">
            <img
              className={styles.icons}
              src={totalLeaveIcon}
              alt="CurrentMonthLeave"
            />
            <Box className={styles.textCont}>
              <Typography className={styles.cardHead}>
                Leave this month
              </Typography>
              <Typography className={styles.cardContent}>
                {addZeroToSingleDigits(presentMonthLeaves)}
              </Typography>
            </Box>
          </Stack>

          <Stack className={styles.cardCont} direction="row">
            <img
              className={styles.icons}
              src={totalLeaveIcon}
              alt="LastMonthLeave"
            />
            <Box className={styles.textCont}>
              <Typography className={styles.cardHead}>
                Leave last month
              </Typography>
              <Typography className={styles.cardContent}>
                {addZeroToSingleDigits(previousMonthLeaves)}
              </Typography>
            </Box>
          </Stack>

          <Stack className={styles.cardCont} direction="row">
            <img className={styles.icons} src={lateDaysIcon} alt="LateDays" />
            <Box className={styles.textCont}>
              <Typography className={styles.cardHead}>Late days</Typography>
              <Typography className={styles.cardContent}>
                {addZeroToSingleDigits(lateComing)}
              </Typography>
            </Box>
          </Stack>

          <Stack className={styles.cardCont} direction="row">
            <img className={styles.icons} src={shortDaysIcon} alt="ShortDays" />
            <Box className={styles.textCont}>
              <Typography className={styles.cardHead}>
                No. of short days
              </Typography>
              <Typography className={styles.cardContent}>
                {addZeroToSingleDigits(shortDays)}
              </Typography>
            </Box>
          </Stack>
        </Stack>

        <Attendence employeeId={employeeId} />

        <LeaveManagement employeeId={employeeId} />

        <EmployeePerformanceFeedback employeeId={employeeId} />

        <AttendanceRequest employeeId={employeeId} />
        
        <LeaveRequests employeeId={employeeId} />

        {openProfile && (
          <OnBoard
            openProp={openProfile}
            handleClose={() => setOpenProfile(false)}
            title="Update Profile"
            currentEmployeeId={employeeId}
          />
        )}
      </Box>
    </>
  );
}
