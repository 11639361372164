import { useQuery } from "@tanstack/react-query";
import apiEarlyLeaving from "../../../services/attendance/apiEarlyLeaving";

export default function EarlyLeavingQuery(currentEmployeeId) {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["EarlyLeaving", currentEmployeeId],
    queryFn: () => apiEarlyLeaving(currentEmployeeId),
    enabled: currentEmployeeId === "" ? false : true,
  });

  const report = data?.data.auto_approve_list.map((ele, idx) => ({
    id: idx,
    col1: ele.application_id,
    col2: ele.employee_name,
    col3: ele.date,
    col4: ele.type,
  }));

  // console.log("report", report);

  return { report, isLoading, refetch };
}
