import { Box, CircularProgress } from "@mui/material";

export default function FullscreenLoader() {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Optional: Slight overlay
        zIndex: 9999, // Ensure it's above other elements
      }}
    >
      <CircularProgress size={40} />
    </Box>
  );
}
