import { useQuery } from "@tanstack/react-query";
import apiTodayOnLeave from "../../../../services/manager/leaveRequest/apiTodayOnLeave";

export default function TodayOnLeaveQuery() {
  const { data } = useQuery({
    queryKey: ["todayOnLeave"],
    queryFn: () => apiTodayOnLeave(),
  });

  const todayLeaveCount = data?.data.total_count;
  const employeeList = data?.data.employee_name_list;

  return { todayLeaveCount, employeeList };
}
