import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Menu,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import styles from "./styles.module.css";
import CloseIcon from "@mui/icons-material/Close";
import DataGridComp from "../../../components/DataGrid/DataGrid";

import { useResponsive } from "../../../hooks/customUseResponsive";
import calenderIcon from "../../../styles/icons/calenderIcon.svg";
import { DateRange } from "react-date-range";
import { usDateFormate } from "../../../hooks/dateFormat";
import { MissingStatusQuery } from "../../../utils/reactQuery/manager/count/MissingStatus";

export default function MissingStatusModal({ openProp, handleClose }) {
  const isMobile = useResponsive("down", "sm");
  const [dateRange, setDateRange] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const d = new Date();

  // Start date: First day of the current month
  const startOfMonth = new Date(d.getFullYear(), d.getMonth(), 1);
  const selectedStartDate = useRef(
    `${startOfMonth.getFullYear()}-${String(
      startOfMonth.getMonth() + 1
    ).padStart(2, "0")}-${String(startOfMonth.getDate()).padStart(2, "0")}`
  );

  // End date: Today
  const selectedEndDate = useRef(
    `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, "0")}-${String(
      d.getDate() - 1
    ).padStart(2, "0")}`
  );

  const [state, setState] = useState([
    {
      startDate: selectedStartDate.current,
      endDate: selectedEndDate.current,
      key: "selection",
    },
  ]);

  // console.log(
  //   "MissingStatusModal selectedStartDate.current",
  //   selectedStartDate.current
  // );
  // console.log(
  //   "MissingStatusModal selectedEndDate.current",
  //   selectedEndDate.current
  // );

  const { missingStatus, isLoading } = MissingStatusQuery({
    startDate: selectedStartDate.current,
    endDate: selectedEndDate.current,
  });

  // console.log("missing status list ", missingStatus)
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "330px" : 1200,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
  };

  const handleClick = (event) => {
    setDateRange(true);
    setAnchorEl(event.currentTarget);
  };

  const handleSubmit = () => {
    selectedStartDate.current = `${state[0].startDate.getFullYear()}-${String(
      state[0].startDate.getMonth() + 1
    ).padStart(2, "0")}-${String(state[0].startDate.getDate()).padStart(
      2,
      "0"
    )}`;

    selectedEndDate.current = `${state[0].endDate.getFullYear()}-${String(
      state[0].endDate.getMonth() + 1
    ).padStart(2, "0")}-${String(state[0].endDate.getDate()).padStart(2, "0")}`;

    setDateRange(false);
  };

  const handleClearBtn = () => {
    selectedEndDate.current = " ";
    selectedStartDate.current = " ";
    setDateRange(false);
  };

  const columns = [
    {
      field: "col1",
      headerName: "Employee Name",
      minWidth: 120,
      flex: 1.4,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col2",
      headerName: "Date",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col3",
      headerName: "Hours Spent",
      minWidth: 120,
      sortable: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
      type: "number",
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col4",
      headerName: "Delay",
      sortable: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
      type: "number",
      renderCell: (value) => <span>{value.value}</span>,
    },
  ];

  return (
    <Modal open={openProp} onClose={handleClose}>
      <Box sx={{ ...style }}>
        <Box className={styles.container}>
          <Box className={styles.greenTab} />
          <Stack direction="row" className={styles.headerContainer}>
            <Typography className={styles.tableHead}>
              Missing Status Report
            </Typography>
            <CloseIcon
              sx={{ color: "#fff", cursor: "pointer" }}
              onClick={handleClose}
            />
          </Stack>
        </Box>
        <Box className={styles.bodyCont}>
          <Stack className={styles.filterByDateCont}>
            <Typography className={styles.filterDate}>
              Filter by Date:
            </Typography>

            <TextField
              onClick={(event) => handleClick(event)}
              value={
                selectedStartDate.current !== " "
                  ? `${usDateFormate(
                      selectedStartDate.current
                    )}-${usDateFormate(selectedEndDate.current)}`
                  : ""
              }
              sx={{
                width: "293px",
                "& .MuiInputBase-input": {
                  padding: "9px", // Adjust padding as needed
                  fontSize: "14px",
                  fontWeight: "500",
                  paddingLeft: "16px",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <img src={calenderIcon} alt="calender" />
                  </InputAdornment>
                ),
              }}
            />
            <Menu
              id="lock-menu1"
              anchorEl={anchorEl}
              open={dateRange}
              onClose={() => setDateRange(false)}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <DateRange
                editableDateInputs={true}
                onChange={(item) => setState([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={state}
              />
              <Stack className={styles.btnCont}>
                <Button onClick={handleSubmit} className={styles.submitBtn}>
                  submit
                </Button>

                <Button
                  className={styles.closeBtn}
                  onClick={handleClearBtn}
                  variant="outlined"
                >
                  clear
                </Button>

                <Button
                  className={styles.closeBtn}
                  onClick={() => setDateRange(false)}
                  variant="outlined"
                >
                  close
                </Button>
              </Stack>
            </Menu>
          </Stack>
          <Box className={styles.datagridCont}>
            {isLoading && (
              <Stack className={styles.loaderCont}>
                <CircularProgress />
              </Stack>
            )}

            <DataGridComp
              columns={columns}
              initialRows={missingStatus ? missingStatus : []}
              pageSize={7}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
