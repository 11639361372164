import { useRef, useState } from "react";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import DataGridComp from "../../../../components/DataGrid/DataGrid";
import styles from "./styles.module.css";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePickerComponent from "../../../../components/DatePickerComp/DatePickerComp";
import { leaveLogSchema } from "../../../../schemas/leaveLog";
import { LoadingButton } from "@mui/lab";
import { usDateFormate } from "../../../../hooks/dateFormat";
import { useResponsive } from "../../../../hooks/customUseResponsive";
import deleteIcon from "../../../../styles/svg/deleteIcon.svg";
import ConfirmModal from "../../../../components/ConfirmationModal/ConfirmModal";
import { deleteApplication } from "../../../../services/leave/apiDeleteApplication";
import toast from "react-hot-toast";
import { LeaveLogListQuery } from "../../../../utils/reactQuery/leave/LeaveList";
import { useQueryClient } from "@tanstack/react-query";
import viewIcon from "../../../../styles/icons/viewicon.svg";
import LeaveLogPopup from "../../../../components/NotesPopup/LeaveLogPopup";

function LeaveLog({ employeeId }) {
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [note, setNote] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const isMobile = useResponsive("down", "sm");
  const startDate = useRef("");
  const endDate = useRef("");
  const queryClient = useQueryClient();
  const { row, refetch, isLoading } = LeaveLogListQuery(
    employeeId,
    startDate.current,
    endDate.current
  );

  const refetchData = async () => {
    await queryClient.refetchQueries({
      queryKey: [
        "leaveLogList",
        { startDate: startDate.current, endDate: endDate.current },
      ],
      exact: true,
    });
  };

  // console.log("LeaveLog row", row);

  const handleDelete = (id) => {
    setConfirmModal(true);
    setSelectedId(id);
  };

  const handleDeleteApplication = () => {
    deleteApplication(selectedId)
      .then((res) => {
        toast.success(res.data.message);
        refetch();
        setConfirmModal(false);
      })
      .catch((err) => {
        console.error(err);
        setConfirmModal(false);
      });
  };
  const columns = [
    {
      field: "col1",
      headerName: "Date",
      minWidth: isMobile ? 150 : 210,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => {
        return (
          <Typography className={styles.dateCont}>
            {usDateFormate(value?.value.startDate)}
            {" - "}
            {usDateFormate(value?.value.endDate)}
          </Typography>
        );
      },
    },
    {
      field: "col2",
      headerName: "Type",
      minWidth: 80,
      flex: 0.5,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col3",
      headerName: "Days",
      headerAlign: "right",
      headerClassName: "paddingClass",
      align: "right",
      minWidth: 80,
      sortable: false,
      flex: 0.5,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col4",
      headerName: "Reason",
      minWidth: 100,
      sortable: false,
      flex: 0.5,
      resizable: false,
      renderCell: (value) => (
        <Stack className={styles.iconCont}>
          <Box
            component="img"
            src={viewIcon}
            alt="view"
            className={styles.icon}
            onClick={() => setNote(value.value)}
          />
        </Stack>
      ),
    },
    {
      field: "col5",
      headerName: "Comp Off Adjusted",
      minWidth: 80,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col6",
      headerName: "Status",
      minWidth: 80,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => (
        <Stack className={styles.statusCont}>
          <Typography
            className={
              value.value === "Approved"
                ? styles.statusApproved
                : styles.statusPending
            }
          >
            {value.value}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "col7",
      headerName: "Actions",
      minWidth: 80,
      sortable: false,
      flex: 0.5,
      resizable: false,
      renderCell: (value) => (
        <Stack className={styles.iconCont}>
          {(value.value.approved === "Pending" ||
            value.value.approved === "Proceed") && (
            <img
              onClick={() => handleDelete(value.value.id)}
              className={styles.icon}
              src={deleteIcon}
              alt="delete"
            />
          )}
        </Stack>
      ),
    },
  ];

  const methods = useForm({
    resolver: yupResolver(leaveLogSchema),
  });

  const onSubmit = async (formData) => {
    setLoadingGenerate(true);

    const start_date = `${formData.startDate.getFullYear()}-${
      formData.startDate.getMonth() + 1
    }-${formData.startDate.getDate()}`;

    const end_date = `${formData.endDate.getFullYear()}-${
      formData.endDate.getMonth() + 1
    }-${formData.endDate.getDate()}`;

    startDate.current = start_date;
    endDate.current = end_date;
    refetchData();

    setLoadingGenerate(false);
  };

  return (
    <Box className={styles.cont}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack direction="row" className={styles.container}>
            <Stack className={styles.dateSection}>
              <Typography className={styles.datePickerlabel}>
                From Date
              </Typography>

              <DatePickerComponent name="startDate" />
            </Stack>

            <Stack className={styles.dateSection}>
              <Typography className={styles.datePickerlabel}>
                To Date
              </Typography>
              <DatePickerComponent name="endDate" />
            </Stack>

            <Box>
              <LoadingButton
                loading={loadingGenerate}
                type="submit"
                variant="contained"
                className={styles.generateBtn}
              >
                Generate Report
              </LoadingButton>
            </Box>
          </Stack>
        </form>
      </FormProvider>
      <Box
        className={styles.datagridCont}
        sx={{
          "& .paddingClass": {
            paddingRight: "10px !important",
          },
        }}
      >
        {isLoading && (
          <Stack className={styles.loaderCont}>
            <CircularProgress />
          </Stack>
        )}
        <DataGridComp columns={columns} initialRows={row || []} />
      </Box>
      {confirmModal && (
        <ConfirmModal
          openProp={confirmModal}
          handleClose={() => setConfirmModal(false)}
          handleConfirm={() => handleDeleteApplication()}
          tabHeader="Delete Confirmation"
          heading="Are You Sure?"
          content="Deleting this item will remove it permanently. Do you want to proceed?"
          btnText="delete"
        />
      )}
      {note && (
        <LeaveLogPopup
          openPop={!!note}
          handleClose={() => setNote("")}
          note={note}
        />
      )}
    </Box>
  );
}

export default LeaveLog;
