import { useQuery } from "@tanstack/react-query";
import { usDateFormate } from "../../../hooks/dateFormat";
import apiInTimeReport from "../../../services/attendance/apiInTimeReport";

export default function InTimeReportQuery(
  startDate,
  endDate,
  currentEmployeeId
) {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["intimereport", startDate, endDate, currentEmployeeId],
    queryFn: () => apiInTimeReport(startDate, endDate, currentEmployeeId),
  });

  const report = data?.data.late_coming_list.map((item, idx) => ({
    id: idx,
    col1: item.employee_name,
    col2: usDateFormate(item.check_in_date),
    col3: {
      time: item.time,
      isLate: item.is_late,
    },
    col4: item.application_id
  }));

  return { report, isLoading, refetch };
}
