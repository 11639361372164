import { useQuery } from "@tanstack/react-query";
import { getAttendance } from "../../../services/attendance/apiAttendance";

export const AttendanceQuery = ({ year, month, employeeId }) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["attendance", year, month, employeeId],
    queryFn: () => getAttendance(year, month, employeeId),
  });
  // console.log("AttendanceQueryData", data);
  const attendanceList = data?.data
    .filter((ele) => ele.status !== "Weekend")
    .map((ele) => {
      return {
        id: ele.date,
        start: ele.date,
        title: ele.status,
        isLateComing: ele.is_late_coming,
        shortHoursCount: ele.short_hours,
        oneWayEntryCount: ele.one_way_entry,
        breakCount: ele.break_count,
        totalHours: ele.total_hours_formatted
      };
    });
  return { attendanceList, isLoading, isError };
};
