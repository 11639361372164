import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { postAddAttendanceOfEmployee } from "../../../services/manager/attendance/apiAddAttendanceOfEmployee";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AddAttendanceSchema from "../../../schemas/addAttendanceSchema";
import { Box, MenuItem, Stack, Typography } from "@mui/material";
import { RHFSelect } from "../../../components/RFH/RFHselect";
import DatePickerComponent from "../../../components/DatePickerComp/DatePickerComp";
import RHFRadioGroup from "../../../components/RFH/RFHradioGroup";
import RHFTimePicker from "../../../components/RFH/RFHtimePicker";
import RHFTextField from "../../../components/RFH/RFHtextfield";
import { LoadingButton } from "@mui/lab";
import styles from "./styles.module.css";
import toast from "react-hot-toast";
import useEmployeeSelection from "../../../hooks/useEmployeeSelection";
import TableHeader from "../../../components/TableHeader/TableHeader";

const options = [
  { value: "in", label: "In" },
  { value: "out", label: "Out" },
  { value: "in-out", label: "In-Out" },
];

export default function AddAttendanceSection() {
  const { emplist, currentEmployee } = useEmployeeSelection();
  const [employeeId, setEmployeeId] = useState(null);

  const defaultValues = {
    start_date: null,
    in_time: null,
    out_time: null,
    application_type: "in",
  };

  const methods = useForm({
    resolver: yupResolver(AddAttendanceSchema),
    defaultValues,
  });

  const selectedType = methods.watch("application_type");

  const { mutate, isPending } = useMutation({
    mutationFn: postAddAttendanceOfEmployee,
    onSuccess: (data) => {
      toast.success(data.data.message);
      methods.reset({
        start_date: null,
        reason: "",
        in_time: null,
        out_time: null,
        employee_id: employeeId,
        application_type: "in",
      });
    },
    onError: (err) => {
      console.error("Error adding attendance for employee", err);
      toast.error(err.response?.data?.message);
    },
  });

  const onSubmit = (formData) => {
    // console.log("formData === ", formData);

    const data = {
      ...formData,
      start_date: `${new Date(formData.start_date).getFullYear()}-${
        new Date(formData.start_date).getMonth() + 1 < 10
          ? "0" + (new Date(formData.start_date).getMonth() + 1)
          : new Date(formData.start_date).getMonth() + 1
      }-${
        new Date(formData.start_date).getDate() < 10
          ? "0" + new Date(formData.start_date).getDate()
          : new Date(formData.start_date).getDate()
      }`,
      application_type: "Missing Entry",
      in_time: formData.in_time
        ? `${new Date(formData.in_time).getHours()}:${new Date(
            formData.in_time
          ).getMinutes()}:${new Date(formData.in_time).getSeconds()}`
        : "",
      out_time: formData.out_time
        ? `${new Date(formData.out_time).getHours()}:${new Date(
            formData.out_time
          ).getMinutes()}:${new Date(formData.out_time).getSeconds()}`
        : "",
    };

    setEmployeeId(formData.employee_id);

    mutate(data);
  };

  useEffect(() => {
    if (currentEmployee) {
      methods.setValue("employee_id", currentEmployee.employee_id);
    }
  }, [currentEmployee, methods]);

  return (
    <Box className={styles.mainContainer}>
      <TableHeader heading="Attendance Approvals" />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack className={styles.formCont}>
            {emplist && (
              <Box className={styles.inputConatiner}>
                <Typography className={styles.label}>Employee Name</Typography>
                <RHFSelect name="employee_id" className={styles.inputField}>
                  {emplist.map((ele) => {
                    return (
                      <MenuItem value={ele.employee_id} key={ele.employee_id}>
                        {ele.name}
                      </MenuItem>
                    );
                  })}
                </RHFSelect>
              </Box>
            )}
            <Box className={styles.inputConatiner}>
              <Typography className={styles.label}>Date</Typography>
              <Stack direction="row">
                <DatePickerComponent
                  name="start_date"
                  className={styles.inputField}
                />
              </Stack>
            </Box>

            <Box className={styles.inputConatiner}>
              <Typography className={styles.label}>Entry Type</Typography>
              <Stack direction="row">
                <RHFRadioGroup
                  options={options}
                  name="application_type"
                  row={true}
                />
              </Stack>
            </Box>
            {(selectedType === "in" || selectedType === "in-out") && (
              <Box className={styles.inputConatiner}>
                <Typography className={styles.label}>In Time</Typography>
                <RHFTimePicker name="in_time" className={styles.inputField} />
              </Box>
            )}

            {(selectedType === "out" || selectedType === "in-out") && (
              <Box className={styles.inputConatiner}>
                <Typography className={styles.label}>Out Time</Typography>
                <RHFTimePicker name="out_time" className={styles.inputField} />
              </Box>
            )}
            <Box className={styles.inputConatiner}>
              <Typography className={styles.label}>Message</Typography>
              <RHFTextField
                name="reason"
                multiline
                rows={4}
                fullWidth
                className={styles.textarea}
                sx={{
                  "& .MuiInputBase-root": {
                    backgroundColor: "#fff !important",
                  },
                  marginRight: 15,
                }}
              />
            </Box>
            <Stack alignItems="end">
              <LoadingButton
                loading={isPending}
                variant="contained"
                type="submit"
                className={styles.btn}
              >
                Apply
              </LoadingButton>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </Box>
  );
}
