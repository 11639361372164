import { useState } from "react";
import styles from "./styles.module.css";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import shortHoursIcon from "../../../styles/icons/lateComing.svg";
import expandIcon from "../../../styles/icons/expandIcon.svg";
import attendanceApprovalIcon from "../../../styles/icons/attendanceApprovalIcon.svg";
import leavesApprovalIcon from "../../../styles/icons/totalLeave.svg";
import MissingStatusModal from "../MissingStatusModal";
import ShortHoursList from "../ShortHours";
import { ShortDaysCount } from "../../../utils/reactQuery/manager/count/ShortDaysCount";
import { MissingStatusCount } from "../../../utils/reactQuery/manager/count/MissingStatusCount";
import AttendanceApprovalCountQuery from "../../../utils/reactQuery/manager/attendance/AttendanceApprovalCount";
import AttendanceApprovalModal from "../AttendanceRequest.js/AttendanceApproval/AttendanceApproval";
import LeaveApprovalCountQuery from "../../../utils/reactQuery/manager/leaveRequest/LeaveApprovalCount";
import Loader from "../../../components/Loader";
import LeaveApprovalModal from "../LeaveRequests.js/LeaveApproval/LeaveApproval";
import Iconify from "../../../components/Icon/Iconify";
import todayOnLeaveIcon from "../../../styles/icons/todayOnLeave.svg";
import TodayOnLeaveQuery from "../../../utils/reactQuery/manager/leaveRequest/TodayOnLeave";
import missingStatusIcon from "../../../styles/icons/missingStatusIcon.svg";

export default function CardSection() {
  const [missingStatusModal, setMissingStatus] = useState(false);
  const [shortHoursModal, setShortHoursModal] = useState(false);
  const [attendanceApprovalModal, setattendanceApprovalModal] = useState(false);
  const [leaveApprovalModal, setLeaveApprovalModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const { shortDayCount, shortHourLoading } = ShortDaysCount();
  const { missingStatusCount, missingStatusLoading } = MissingStatusCount();
  const {
    attendanceApprovalCount,
    attendanceApprovalCountLoading,
    attendanceApprovalCountRefetch,
  } = AttendanceApprovalCountQuery();

  const {
    leaveApprovalCount,
    leaveApprovalCountLoading,
    leaveApprovalCountRefetch,
  } = LeaveApprovalCountQuery();

  const { todayLeaveCount, employeeList } = TodayOnLeaveQuery();

  const handleMenuOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // console.log("missing status count", missingStatusCount);

  return (
    <>
      <Box
        className={[
          styles.employeeOnLeaveContainer,
          styles.employeeOnLeaveContainerMargin,
        ]}
      >
        <Typography className={styles.title}>Team Dashboard</Typography>
        <Box
          className={styles.employeeOnLeaveSection}
          onClick={todayLeaveCount > 0 ? handleMenuOpen : null}
        >
          <img src={todayOnLeaveIcon} alt="todayOnLeaveIcon" />

          <Typography>Today on leave ({`${todayLeaveCount || 0}`})</Typography>
          <Iconify
            icon="mdi:keyboard-arrow-right"
            sx={{ color: "rgba(69, 95, 105, 0.5)", cursor: "pointer" }}
          />
        </Box>

        <Menu
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleMenuClose}
          autoFocus={false}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {employeeList &&
            employeeList?.map((ele, idx) => (
              <MenuItem
                key={idx}
                onClick={handleMenuClose}
                className={styles.employeeMenuItem}
              >
                {ele}
              </MenuItem>
            ))}
        </Menu>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={3}>
          <Box
            className={styles.cardItem}
            onClick={() => setMissingStatus(true)}
          >
            <Box className={styles.greenTab} />
            <Stack className={styles.expandCont}>
              <Stack className={styles.expandCard}>
                <img
                  className={styles.expandIcon}
                  src={expandIcon}
                  alt="expand"
                />
                <Typography className={styles.expandContent}>
                  Expand in detail
                </Typography>
              </Stack>
            </Stack>

            <Stack className={styles.cardMainSection}>
              <img
                className={styles.mainCardIcon}
                src={missingStatusIcon}
                alt="missingStatusIcon"
              />
              <Typography className={styles.mainContent}>
                Missing Status
              </Typography>
            </Stack>

            <Stack className={styles.cardFooter}>
              <Stack className={styles.employeeCont}>
                {missingStatusLoading && <Loader />}
                <Typography className={styles.employeeCount}>
                  {missingStatusCount < 10
                    ? `0${missingStatusCount}`
                    : missingStatusCount}
                </Typography>
                <Typography className={styles.employeeText}>
                  Employees
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <Box
            className={styles.cardItem}
            onClick={() => setShortHoursModal(true)}
          >
            <Box className={styles.greenTab} />
            <Stack className={styles.expandCont}>
              <Stack className={styles.expandCard}>
                <img
                  className={styles.expandIcon}
                  src={expandIcon}
                  alt="expand"
                />
                <Typography className={styles.expandContent}>
                  Expand in detail
                </Typography>
              </Stack>
            </Stack>

            <Stack className={styles.cardMainSection}>
              <img
                className={styles.mainCardIcon}
                src={shortHoursIcon}
                alt="short hours"
              />
              <Typography className={styles.mainContent}>
                Short Hours
              </Typography>
            </Stack>

            <Stack className={styles.cardFooter}>
              <Stack className={styles.employeeCont}>
                {shortHourLoading && <Loader />}
                <Typography className={styles.employeeCount}>
                  {shortDayCount < 10 ? `0${shortDayCount}` : shortDayCount}
                </Typography>
                <Typography className={styles.employeeText}>
                  Employees
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <Box
            className={[styles.cardItem]}
            onClick={() => setattendanceApprovalModal(true)}
          >
            <Box className={styles.greenTab} />
            <Stack className={styles.expandCont}>
              <Stack className={styles.expandCard}>
                <img
                  className={styles.expandIcon}
                  src={expandIcon}
                  alt="expand"
                />
                <Typography className={styles.expandContent}>
                  Expand in detail
                </Typography>
              </Stack>
            </Stack>

            <Stack className={styles.cardMainSection}>
              <img
                className={styles.mainCardIcon}
                src={attendanceApprovalIcon}
                alt="attendanceApprovalIcon"
              />
              <Typography className={styles.mainContent}>
                Attendance Approval
              </Typography>
            </Stack>

            <Stack className={styles.cardFooter}>
              <Stack className={styles.employeeCont}>
                {attendanceApprovalCountLoading && <Loader />}
                <Typography className={styles.employeeCount}>
                  {attendanceApprovalCount < 10
                    ? `0${attendanceApprovalCount}`
                    : attendanceApprovalCount}
                </Typography>
                <Typography className={styles.employeeText}>
                  Employees
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <Box
            className={[styles.cardItem, styles.lastCardItem]}
            onClick={() => setLeaveApprovalModal(true)}
          >
            <Box className={styles.greenTab} />
            <Stack className={styles.expandCont}>
              <Stack className={styles.expandCard}>
                <img
                  className={styles.expandIcon}
                  src={expandIcon}
                  alt="expand"
                />
                <Typography className={styles.expandContent}>
                  Expand in detail
                </Typography>
              </Stack>
            </Stack>

            <Stack className={styles.cardMainSection}>
              <img
                className={styles.mainCardIcon}
                src={leavesApprovalIcon}
                alt="leavesApprovalIcon"
              />
              <Typography className={styles.mainContent}>
                Leaves Approval
              </Typography>
            </Stack>

            <Stack className={styles.cardFooter}>
              <Stack className={styles.employeeCont}>
                {leaveApprovalCountLoading && <Loader />}
                <Typography className={styles.employeeCount}>
                  {leaveApprovalCount < 10
                    ? `0${leaveApprovalCount}`
                    : leaveApprovalCount}
                </Typography>
                <Typography className={styles.employeeText}>
                  Employees
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Grid>
      </Grid>

      <MissingStatusModal
        openProp={missingStatusModal}
        handleClose={() => setMissingStatus(false)}
      />

      <ShortHoursList
        openProp={shortHoursModal}
        handleClose={() => setShortHoursModal(false)}
      />

      <AttendanceApprovalModal
        open={attendanceApprovalModal}
        handleClose={() => {
          attendanceApprovalCountRefetch();
          setattendanceApprovalModal(false);
        }}
      />

      <LeaveApprovalModal
        open={leaveApprovalModal}
        handleClose={() => {
          leaveApprovalCountRefetch();
          setLeaveApprovalModal(false);
        }}
      />
    </>
  );
}
