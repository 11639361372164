import { useQuery } from "@tanstack/react-query";
import apiAttendanceApprovalCount from "../../../../services/manager/attendance/apiAttendanceApprovalCount";

export default function AttendanceApprovalCountQuery() {
  const {
    data,
    isLoading: attendanceApprovalCountLoading,
    refetch: attendanceApprovalCountRefetch,
  } = useQuery({
    queryKey: ["attendanceApprovalCount"],
    queryFn: () => apiAttendanceApprovalCount(),
  });

  const attendanceApprovalCount = data?.data.attendance_approval_count;

  // console.log("attendanceApprovalCount", attendanceApprovalCount);

  return {
    attendanceApprovalCount,
    attendanceApprovalCountLoading,
    attendanceApprovalCountRefetch,
  };
}
