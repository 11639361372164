import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";
import styles from "./styles.module.css";
import DataGridComp from "../../../components/DataGrid/DataGrid";
import AttendanceRequestQuery from "../../../utils/reactQuery/manager/attendance/AttendanceRequest";
import rejectIcon from "../../../styles/icons/crossIcon.svg";
import approvedIcon from "../../../styles/icons/approvedIcon.svg";
import viewIcon from "../../../styles/icons/viewicon.svg";
import ManagerNote from "../../../components/managerNote/ManagerNote";
import ConfirmModal from "../../../components/ConfirmationModal/ConfirmModal";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postApproveAttendanceRequest } from "../../../services/manager/attendance/apiApprovedAttendanceRequest";
import { postRejectAttendanceRequest } from "../../../services/manager/attendance/apiRejectAttendanceRequest";
import RejectRequestModal from "../../../components/RejectRequestModal";
import AddAttendanceModal from "./AddAttendance";
import { canApproveAttendance, isAdmin, isManager } from "../../../utils/helpers";
import TableHeader from "../../../components/TableHeader/TableHeader";

export default function AttendanceRequest({ employeeId }) {
  const { attendanceRequestList, refetch } = AttendanceRequestQuery(employeeId);
  const [notePopover, setNotePopover] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [approveModal, setApproveModal] = useState(false);
  const [rejectRequestModal, setRejectRequestModal] = useState(false);
  const [modals, setModals] = useState({ addAttendance: false });
  const queryClient = useQueryClient();
  const listOfEmp = queryClient.getQueryData(["employeeUnderManager"])?.data;

  const { mutate } = useMutation({
    mutationFn: postApproveAttendanceRequest,
    onSuccess: (data) => {
      refetch();
      toast.success(data.data.message);
      setApproveModal(false);
    },
    onError: (data) => {
      console.error("error employee feedback", data);
      toast.error(data.response.data.error);
    },
    onSettled: () => {
      setApproveModal(false);
    },
  });

  const { mutate: mutateRejectRequest, isPending } = useMutation({
    mutationFn: postRejectAttendanceRequest,
    onSuccess: (data) => {
      refetch();
      toast.success(data.data.message);
      setApproveModal(false);
    },
    onError: (data) => {
      console.error("error employee feedback", data);
    },
    onSettled: () => {
      setRejectRequestModal(false);
    },
  });

  const handleView = (id) => {
    setNotePopover(true);
    setSelectedId(id);
  };

  const handleApproveApplication = (id) => {
    setSelectedId(id);
    setApproveModal(true);
  };

  const confirmApproveApplication = () => {
    mutate(selectedId);
  };

  const handleRejectApplication = (id) => {
    setSelectedId(id);
    setRejectRequestModal(true);
  };

  const handleRejectRequest = (reason) => {
    mutateRejectRequest({ id: selectedId, reason });
  };

  const columns = [
    {
      field: "col1",
      headerName: "Employee Name",
      minWidth: 127,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col2",
      headerName: "Entry Type",
      minWidth: 127,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col3",
      headerName: "In",
      minWidth: 126,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col4",
      headerName: "Out",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col5",
      headerName: "Date",
      minWidth: 120,
      flex: 1,
      sortable: true,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      sortComparator: (date1, date2) => new Date(date1) - new Date(date2),
    },
    {
      field: "col6",
      headerName: "Date Created",
      minWidth: 120,
      flex: 1,
      sortable: true,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      sortComparator: (date1, date2) => new Date(date1) - new Date(date2),
    },
    {
      field: "col7",
      headerName: "Status",
      minWidth: 120,
      flex: 1,
      sortable: true,
      resizable: false,
      renderCell: (value) => (
        <Stack className={styles.statusCont}>
          <Typography
            className={
              value.value === "Approved"
                ? styles.statusApproved
                : styles.statusPending
            }
          >
            {value.value}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "col8",
      headerName: "Actions",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => (
        <Stack className={styles.iconsCont}>
          <img
            className={styles.icon}
            src={viewIcon}
            onClick={() => handleView(value.value.id)}
            alt="view"
          />
          {/* Manager actions */}
          {isManager() && canApproveAttendance() && (
            <>
              {/* Manager can approve in Pending or Rejected status */}
              {(value.value.status === "Pending" ||
                value.value.status === "Rejected") && (
                <img
                  className={styles.icon}
                  src={approvedIcon}
                  onClick={() => handleApproveApplication(value.value.id)}
                  alt="approve"
                />
              )}
              {/* Manager can reject in Pending or Proceed status */}
              {(value.value.status === "Pending" ||
                value.value.status === "Proceed") && (
                <img
                  className={styles.icon}
                  src={rejectIcon}
                  onClick={() => handleRejectApplication(value.value.id)}
                  alt="reject"
                />
              )}
            </>
          )}

          {/* Admin actions */}
          {isAdmin() && (
            <>
              {/* Admin can approve in Proceed or Rejected status */}
              {(value.value.status === "Proceed" ||
                value.value.status === "Rejected") && (
                <img
                  className={styles.icon}
                  src={approvedIcon}
                  onClick={() => handleApproveApplication(value.value.id)}
                  alt="approve"
                />
              )}
              {/* Admin can reject in Proceed or Approved status */}
              {(value.value.status === "Proceed" ||
                value.value.status === "Approved") && (
                <img
                  className={styles.icon}
                  src={rejectIcon}
                  onClick={() => handleRejectApplication(value.value.id)}
                  alt="reject"
                />
              )}
            </>
          )}
        </Stack>
      ),
    },
  ];
  return (
    <Box className={styles.mainContainer}>
      <TableHeader
        heading="Attendance Approvals"
        btnicon={isManager() ? undefined : "ic:outline-plus"}
        handleModal={() => setModals({ ...modals, addAttendance: true })}
      />
      <Box className={styles.datagridCont}>
        <DataGridComp
          columns={columns}
          initialRows={attendanceRequestList ? attendanceRequestList : []}
          pageSize={20}
        />
      </Box>
      {notePopover && (
        <ManagerNote
          openPop={notePopover}
          handleClose={() => setNotePopover(false)}
          id={selectedId}
        />
      )}
      {approveModal && (
        <ConfirmModal
          openProp={approveModal}
          handleClose={() => setApproveModal(false)}
          handleConfirm={() => confirmApproveApplication()}
          tabHeader="Approve Request"
          content="Do you want to proceed with approving this request?"
          btnText="approve"
        />
      )}
      {rejectRequestModal && (
        <RejectRequestModal
          openProp={rejectRequestModal}
          handleClose={() => setRejectRequestModal(false)}
          handleConfirm={handleRejectRequest}
          isPending={isPending}
        />
      )}
      {modals.addAttendance && (
        <AddAttendanceModal
          openProp={modals.addAttendance}
          handleClose={() => setModals({ ...modals, addAttendance: false })}
          listOfEmp={listOfEmp}
          employeeId={employeeId}
        />
      )}
    </Box>
  );
}
