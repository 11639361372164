import styles from "./styles.module.css";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Loader from "../Loader";
import DataGridComp from "../DataGrid/DataGrid";
import EarlyLeavingQuery from "../../utils/reactQuery/attendance/EarlyLeaving";
import { useResponsive } from "../../hooks/customUseResponsive";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import apiApproveEarlyLeaving from "../../services/attendance/apiApproveEarlyLeaving";

export default function EarlyLeaving({ currentEmployeeId }) {
  const isMobile = useResponsive("down", "sm");
  const [ids, setIds] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { report, isLoading, refetch } = EarlyLeavingQuery(currentEmployeeId);

  const { mutate } = useMutation({
    mutationFn: apiApproveEarlyLeaving,
    onSuccess: (data) => {
      toast.success(data.data.message);
      refetch();
    },
    onError: (data) => {
      console.error("error approving early leaving", data);
      toast.error("Something went wrong. Please try again.");
    },
    onSettled: () => {
      setIds([]);
      setIsSubmitting(false);
    },
  });

  const handleChange = async (e, value) => {
    // console.log("event", e.target.checked);

    if (e.target.checked) {
      setIds([...ids, value]);
    } else {
      setIds(ids.filter((id) => id !== value));
    }
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    mutate(ids[0]);
  };

  const columns = [
    {
      field: "col1",
      headerName: " ",
      minWidth: isMobile ? 10 : 20,
      flex: 0.1,
      sortable: false,
      resizable: false,
      renderCell: (value) => (
        <input
          type="checkbox"
          onChange={(e) => handleChange(e, value.value)}
          name="earlyLeaving"
          key={`earlyLeaving${value.value}`}
        />
      ),
    },
    {
      field: "col2",
      headerName: "Employee Name",
      minWidth: isMobile ? 150 : 200,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col3",
      headerName: "Leave Date",
      minWidth: isMobile ? 150 : 200,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col4",
      headerName: "Leave Type",
      minWidth: isMobile ? 150 : 200,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
  ];

  return (
    <Box className={styles.dataGridContainer}>
      {isLoading && (
        <Stack className={styles.loaderCont}>
          <Loader />
        </Stack>
      )}
      <DataGridComp
        columns={columns || []}
        initialRows={report || []}
        footer={false}
        checkboxSelection={true}
      />

      <Box className={styles.btnContainer}>
        <LoadingButton
          type="button"
          variant="contained"
          loading={isSubmitting}
          disabled={ids?.length > 1}
          onClick={handleSubmit}
        >
          Approve
        </LoadingButton>
      </Box>
    </Box>
  );
}
