import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import styles from "./styles.module.css";
import DataGridComp from "../../../components/DataGrid/DataGrid";
import LeaveRequestQuery from "../../../utils/reactQuery/manager/leaveRequest/LeaveRequestQuery";
import rejectIcon from "../../../styles/icons/crossIcon.svg";
import approvedIcon from "../../../styles/icons/approvedIcon.svg";
import viewIcon from "../../../styles/icons/viewicon.svg";
import compoffIcon from "../../../styles/icons/mdigift.svg";
import ManagerNote from "../../../components/managerNote/ManagerNote";
import ConfirmModal from "../../../components/ConfirmationModal/ConfirmModal";
import toast from "react-hot-toast";
import { useMutation } from "@tanstack/react-query";
import { postApproveLeaveRequest } from "../../../services/manager/leaveRequest/apiApprovedLeaveRequest";
import RejectRequestModal from "../../../components/RejectRequestModal";
import { postRejectLeaveRequest } from "../../../services/manager/leaveRequest/apiRejectleaveRequest";
import AddLeaveModal from "../../Dashboard/LeaveManagement/AddLeaveModal/AddLeaveModal";
import TableHeader from "../../../components/TableHeader/TableHeader";
import { isAdmin, isManager } from "../../../utils/helpers";
import AdjustCompOff from "../CompOffRequest/AdjustCompOff";
import GetCompOffDateQuery from "../../../utils/reactQuery/manager/compoOff/GetCompOffDates";

export default function LeaveRequests({ employeeId }) {
  const [notePopover, setNotePopover] = useState(false);
  const { leaveRequestList, refetch, isLoading } =
    LeaveRequestQuery(employeeId);
  const [selectedId, setSelectedId] = useState("");
  const [approveModal, setApproveModal] = useState(false);
  const [rejectRequestModal, setRejectRequestModal] = useState(false);
  const [modal, setModal] = useState({ addLeave: false });
  const [adjustCompOff, setAdjustCompOff] = useState(false);
  const [dates, setDates] = useState({ startDate: null, endDate: null });

  const { availableCompOffs, refetch: refetchCompOff } =
    GetCompOffDateQuery(employeeId);

  const { mutate } = useMutation({
    mutationFn: postApproveLeaveRequest,
    onSuccess: (data) => {
      refetch();
      toast.success(data.data.message);
    },
    onError: (data) => {
      console.error("error employee feedback", data);
    },
    onSettled: () => {
      setApproveModal(false);
    },
  });

  const { mutate: mutateRejectRequest, isPending } = useMutation({
    mutationFn: postRejectLeaveRequest,
    onSuccess: (data) => {
      refetch();
      toast.success(data.data.message);
    },
    onError: (data) => {
      console.error("error employee feedback", data);
    },
    onSettled: () => {
      setRejectRequestModal(false);
    },
  });

  const handleView = (id) => {
    setNotePopover(true);
    setSelectedId(id);
  };

  const handleApproveApplication = (id) => {
    setSelectedId(id);
    setApproveModal(true);
  };

  const confirmApproveApplication = () => {
    mutate(selectedId);
  };

  const handleRejectApplication = (id) => {
    setSelectedId(id);
    setRejectRequestModal(true);
  };

  const handleRejectRequest = (reason) => {
    mutateRejectRequest({ id: selectedId, reason });
  };

  const handleCompOff = (id, startDate, endDate) => {
    setSelectedId(id);
    setDates({ startDate, endDate });
    setAdjustCompOff(true);
  };

  const columns = [
    {
      field: "col1",
      headerName: "Employee Name",
      minWidth: 127,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col2",
      headerName: "Start Date",
      minWidth: 127,
      flex: 1,
      sortable: true,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      sortComparator: (date1, date2) => new Date(date1) - new Date(date2),
    },
    {
      field: "col3",
      headerName: "End Date",
      minWidth: 126,
      flex: 1,
      sortable: true,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      sortComparator: (date1, date2) => new Date(date1) - new Date(date2),
    },
    {
      field: "col4",
      headerName: "Total Days",
      headerAlign: "right",
      align: "right",
      headerClassName: "rightPadding",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col5",
      headerName: "Leave Type",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col6",
      headerName: "Applied On",
      minWidth: 120,
      flex: 1,
      sortable: true,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      sortComparator: (date1, date2) => new Date(date1) - new Date(date2),
    },
    {
      field: "col7",
      headerName: "Status",
      minWidth: 120,
      flex: 1,
      sortable: true,
      resizable: false,
      renderCell: (value) => (
        <Stack className={styles.statusCont}>
          <Typography
            className={
              value.value === "Approved"
                ? styles.statusApproved
                : styles.statusPending
            }
          >
            {value.value}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "col8",
      headerName: "Compoff Adjusted Date",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col9",
      headerName: "Actions",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => (
        <Stack className={styles.iconsCont}>
          <img
            className={styles.icon}
            src={viewIcon}
            onClick={() => handleView(value.value.id)}
            alt="view"
          />

          {isAdmin() &&
            availableCompOffs?.length > 0 &&
            !value.value.isCompOffAdjusted && (
              <img
                className={styles.icon}
                src={compoffIcon}
                alt="compoff"
                onClick={() =>
                  handleCompOff(
                    value.value.id,
                    value.value.startDate,
                    value.value.endDate
                  )
                }
              />
            )}

          {/* Manager actions */}
          {isManager() && (
            <>
              {/* Manager can approve in Pending or Rejected status */}
              {(value.value.status === "Pending" ||
                value.value.status === "Rejected") && (
                <img
                  className={styles.icon}
                  src={approvedIcon}
                  onClick={() => handleApproveApplication(value.value.id)}
                  alt="approve"
                />
              )}
              {/* Manager can reject in Pending or Proceed status */}
              {(value.value.status === "Pending" ||
                value.value.status === "Proceed") && (
                <img
                  className={styles.icon}
                  src={rejectIcon}
                  onClick={() => handleRejectApplication(value.value.id)}
                  alt="reject"
                />
              )}
            </>
          )}

          {/* Admin actions */}
          {isAdmin() && (
            <>
              {/* Admin can approve in Proceed or Rejected status */}
              {(value.value.status === "Proceed" ||
                value.value.status === "Rejected") && (
                <img
                  className={styles.icon}
                  src={approvedIcon}
                  onClick={() => handleApproveApplication(value.value.id)}
                  alt="approve"
                />
              )}
              {/* Admin can reject in Proceed or Approved status */}
              {(value.value.status === "Proceed" ||
                value.value.status === "Approved") && (
                <img
                  className={styles.icon}
                  src={rejectIcon}
                  onClick={() => handleRejectApplication(value.value.id)}
                  alt="reject"
                />
              )}
            </>
          )}
        </Stack>
      ),
    },
  ];

  return (
    <Box className={styles.mainContainer}>
      <TableHeader
        heading="Leaves"
        btnicon={isManager() ? undefined : "ic:outline-plus"}
        handleModal={() => setModal({ ...modal, addLeave: true })}
      />
      <Box
        className={styles.datagridCont}
        sx={{
          "& .rightPadding": {
            paddingRight: "8px !important",
          },
        }}
      >
        {isLoading && (
          <Stack className={styles.loaderCont}>
            <CircularProgress />
          </Stack>
        )}

        <DataGridComp
          columns={columns}
          initialRows={leaveRequestList ? leaveRequestList : []}
          pageSize={20}
        />
      </Box>
      {notePopover && (
        <ManagerNote
          openPop={notePopover}
          handleClose={() => setNotePopover(false)}
          id={selectedId}
        />
      )}
      {approveModal && (
        <ConfirmModal
          openProp={approveModal}
          handleClose={() => setApproveModal(false)}
          handleConfirm={() => confirmApproveApplication()}
          tabHeader="Approve Request"
          content="Do you want to proceed with approving this request?"
          btnText="approve"
        />
      )}
      {rejectRequestModal && (
        <RejectRequestModal
          openProp={rejectRequestModal}
          handleClose={() => setRejectRequestModal(false)}
          handleConfirm={handleRejectRequest}
          isPending={isPending}
        />
      )}
      {modal.addLeave && (
        <AddLeaveModal
          openProp={modal.addLeave}
          handleClose={() => setModal({ ...modal, addLeave: false })}
          employeeId={employeeId}
        />
      )}
      {adjustCompOff && (
        <AdjustCompOff
          openProp={adjustCompOff}
          handleClose={() => setAdjustCompOff(false)}
          dates={dates}
          leaveId={selectedId}
          refetch={refetch}
          availableCompOffs={availableCompOffs}
          refetchCompOff={refetchCompOff}
        />
      )}
    </Box>
  );
}
