import { Box, Stack } from "@mui/material";
import { useState } from "react";
import styles from "./styles.module.css";
import DataGridComp from "../../../components/DataGrid/DataGrid";
import { EmployeeFeedbackListQuery } from "../../../utils/reactQuery/manager/EmployeeFeedback/EmployeeFeedbackList";
import viewIcon from "../../../styles/icons/viewicon.svg";
import editIcon from "../../../styles/icons/editIcon.svg";
import AddFeedbackModal from "./AddFeedbackModal";
import EditFeedback from "./EditFeedback";
import TableHeader from "../../../components/TableHeader/TableHeader";
import NotesPopup from "../../../components/NotesPopup/NotesPopup";
import UserFeedbackListQuery from "../../../utils/reactQuery/manager/EmployeeFeedback/UserFeedbackList";
import { isUser } from "../../../utils/helpers";
import { usDateFormate } from "../../../hooks/dateFormat";
import Loader from "../../../components/Loader";
import { useLocation } from "react-router-dom";

export default function EmployeePerformanceFeedback({ employeeId }) {
  const { feedbackList, refetch, isLoading } =
    employeeId === undefined
      ? UserFeedbackListQuery()
      : EmployeeFeedbackListQuery(employeeId);
  const [addFeedbackModal, setAddFeedbackModal] = useState(false);
  const [viewFeedbackModal, setViewFeedbackModal] = useState(false);
  const [editFeedbackModal, setEditFeedbackModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const location = useLocation();
  const isDashboard = location.pathname.includes("dashboard");
  // console.log("isDashboard", isDashboard);

  const handleOpenViewFeedback = (id) => {
    setViewFeedbackModal(true);
    setSelectedId(id);
  };

  const handleOpenEditFeedback = (id) => {
    setEditFeedbackModal(true);
    setSelectedId(id);
  };

  const columns = [
    {
      field: "col1",
      headerName: "Employee Name",
      minWidth: 127,
      flex: 1.7,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col2",
      headerName: "Role",
      minWidth: 127,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col3",
      headerName: "Date Added",
      minWidth: 126,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => (
        <span>{value.value ? usDateFormate(value.value) : ""}</span>
      ),
    },
    {
      field: "col4",
      headerName: "Action",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => (
        <Stack className={styles.iconsCont}>
          <img
            className={styles.icon}
            src={viewIcon}
            onClick={() => handleOpenViewFeedback(value?.value)}
            alt="view icon"
          />
          {!isDashboard && (
            <img
              className={styles.icon}
              src={editIcon}
              onClick={() => handleOpenEditFeedback(value?.value)}
              alt="edit icon"
            />
          )}
        </Stack>
      ),
    },
  ];

  return (
    (!isDashboard || (isDashboard && feedbackList?.length > 0)) && (
      <Box className={styles.mainContainer}>
        <TableHeader
          heading="Performance Feedback"
          btnicon={!isUser() && !isDashboard && "ic:outline-plus"}
          handleModal={
            !isUser() && !isDashboard && (() => setAddFeedbackModal(true))
          }
        />

        <Box className={styles.datagridCont}>
          {isLoading && (
            <Stack className={styles.loaderCont}>
              <Loader />
            </Stack>
          )}
          <DataGridComp
            columns={columns}
            initialRows={feedbackList ? feedbackList : []}
          />
        </Box>

        <AddFeedbackModal
          openProp={addFeedbackModal}
          handleClose={() => setAddFeedbackModal(false)}
          refetch={refetch}
          employeeId={employeeId}
        />

        {viewFeedbackModal && (
          <NotesPopup
            openPop={viewFeedbackModal}
            handleClose={() => setViewFeedbackModal(false)}
            id={selectedId}
            feedback={true}
          />
        )}

        {editFeedbackModal && (
          <EditFeedback
            openProp={editFeedbackModal}
            handleClose={() => setEditFeedbackModal(false)}
            id={selectedId}
            employeeId={employeeId}
          />
        )}
      </Box>
    )
  );
}
