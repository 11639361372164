import { FormProvider, useForm } from "react-hook-form";
import { useResponsive } from "../../hooks/customUseResponsive";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Grid,
  // InputAdornment,
  MenuItem,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./styles.module.css";
import RHFTextField from "../RFH/RFHtextfield";
import RHFRadioGroup from "../RFH/RFHradioGroup";
import { RHFFileUploader } from "../RFH/RFHfileUploader";
import { RHFMultiCheckbox } from "../RFH/RFHCheckbox";
import { LoadingButton } from "@mui/lab";
import employeeDetailsSchema from "../../schemas/employeeDetails";
import DatePickerComponent from "../DatePickerComp/DatePickerComp";
import { RHFSelect } from "../RFH/RFHselect";
import { useEffect, useState } from "react";
import { PersonalInfoQuery } from "../../utils/reactQuery/profile/PersonalInfo";
import { useMutation } from "@tanstack/react-query";
import onBoardEmployee from "../../services/manager/onBoard/apiOnBoardEmployee";
import toast from "react-hot-toast";
import { formatDate, isAdmin } from "../../utils/helpers";
import ManagersListQuery from "../../utils/reactQuery/ManagersList";
import updateEmployeeDetails from "../../services/manager/employeeDetails/apiUpdateEmployeeDetails";
import dayjs from "dayjs";
import Loader from "../Loader";

const genderOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

const statusOptions = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];

const permissionsOptions = [
  { value: "AddAttendance", label: "Add Attendance" },
  { value: "ApproveAttendance", label: "Approve Attendance" },
];

export default function OnBoard({
  openProp,
  handleClose,
  title,
  currentEmployeeId,
}) {
  const isMobile = useResponsive("down", "sm");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? 350 : 1100,
    bgcolor: "#F8F9FA",
    boxShadow: 24,
    borderRadius: "10px",
    maxHeight: "90vh",
    overflowY: "auto",
  };

  const overrideTextFieldStyle = {
    "& .MuiOutlinedInput-input": {
      padding: isMobile ? "6px" : "10px",
      color: "#6C757D",
      backgroundColor: "#fff",
      border: "1.5px solid #CED4DA",
      borderRadius: "4px",
      fontSize: isMobile ? "12px" : "14px",
      fontWeight: 400,
    },
  };
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const { managersList } = ManagersListQuery();

  const { employee, documents, refetch } = PersonalInfoQuery(currentEmployeeId);
  // console.log("OnBoard employee data", employee);
  // console.log("OnBoard employee documents", documents);

  const { mutate, isPending } = useMutation({
    mutationFn: onBoardEmployee,
    onSuccess: (data) => {
      // console.log("success repsonse", data);
      toast.success(data.data.message);
      methods.reset();
      handleClose();
    },
    onError: (data) => {
      console.error("error onBoarding employee", data.response.data);
      toast.error(data.response.data.error);
    },
  });

  const { mutate: updateMutation, isPending: isUpdating } = useMutation({
    mutationFn: updateEmployeeDetails,
    onSuccess: (data) => {
      // console.log("success repsonse", data);
      toast.success(data.data.message);
      methods.reset();
      handleClose();
      refetch();
    },
    onError: (data) => {
      console.error("error updating employee", data.response.data);
      toast.error(data.response.data.error);
    },
  });

  const defaultValues = {
    name: "",
    graduationYear: null,
    education: "",
    experienceYear: "",
    experienceMonth: "",
    phoneNumber: "",
    email: "",
    employeeId: "",
    fileNumber: "",
    designation: "",
    technology: "",
    managerId: "",
    joiningDate: null,
    relievingDate: null,
    documentLink: "",
    gender: "",
    dob: null,
    currentAddress: "",
    currentCity: "",
    currentState: "",
    currentZip: "",
    permanentAddress: "",
    permanentCity: "",
    permanentState: "",
    permanentZip: "",
    adharCard: "",
    panCard: "",
    driverLicense: "",
    pfAccount: "",
    esicNo: "",
    ldapUsername: "",
    fatherName: "",
    motherName: "",
    documents: null,
    status: "",
    permission: [],
  };

  const methods = useForm({
    resolver: yupResolver(employeeDetailsSchema),
    defaultValues,
  });

  const onSubmit = (formData) => {
    // console.log("formData === ", formData);
    const formDataToSubmit = new FormData();

    formDataToSubmit.append("name", formData.name || "");
    formDataToSubmit.append("graduationYear", formData.graduationYear || "");
    formDataToSubmit.append("education", formData.education || "");
    formDataToSubmit.append("experienceYear", formData.experienceYear || "");
    formDataToSubmit.append("experienceMonth", formData.experienceMonth || "");
    formDataToSubmit.append("phoneNumber", formData.phoneNumber || "");
    formDataToSubmit.append("email", formData.email || "");
    formDataToSubmit.append("employeeId", formData.employeeId || null);
    formDataToSubmit.append("fileNumber", formData.fileNumber || "");
    formDataToSubmit.append("designation", formData.designation || "");
    formDataToSubmit.append("technology", formData.technology || "");
    formDataToSubmit.append("managerId", formData.managerId || "");
    formDataToSubmit.append(
      "joiningDate",
      formData.joiningDate ? formatDate(formData.joiningDate) : null
    );
    formDataToSubmit.append(
      "relievingDate",
      formData.relievingDate ? formatDate(formData.relievingDate) : null
    );
    formDataToSubmit.append("documentLink", formData.documentLink || "");
    formDataToSubmit.append("gender", formData.gender || "");
    formDataToSubmit.append(
      "dob",
      formData.dob ? formatDate(formData.dob) : null
    );
    formDataToSubmit.append("currentAddress", formData.currentAddress || "");
    formDataToSubmit.append("currentCity", formData.currentCity || "");
    formDataToSubmit.append("currentState", formData.currentState || "");
    formDataToSubmit.append("currentZip", formData.currentZip || "");
    formDataToSubmit.append(
      "permanentAddress",
      formData.permanentAddress || ""
    );
    formDataToSubmit.append("permanentCity", formData.permanentCity || "");
    formDataToSubmit.append("permanentState", formData.permanentState || "");
    formDataToSubmit.append("permanentZip", formData.permanentZip || "");
    formDataToSubmit.append("adharCard", formData.adharCard || "");
    formDataToSubmit.append("panCard", formData.panCard || "");
    formDataToSubmit.append("driverLicense", formData.driverLicense || "");
    formDataToSubmit.append("pfAccount", formData.pfAccount || "");
    formDataToSubmit.append("esicNo", formData.esicNo || "");
    formDataToSubmit.append("ldapUsername", formData.ldapUsername || "");
    formDataToSubmit.append("fatherName", formData.fatherName || "");
    formDataToSubmit.append("motherName", formData.motherName || "");
    formDataToSubmit.append("documents", formData.documents || null);
    formDataToSubmit.append("status", formData.status || "");

    // Append permissions array if it exists and has values
    if (formData.permission && formData.permission.length > 0) {
      formDataToSubmit.append(
        "permission",
        JSON.stringify(formData.permission)
      );
    } else {
      formDataToSubmit.append("permission", ""); // Append empty if no permissions
    }

    currentEmployeeId
      ? updateMutation(formDataToSubmit)
      : mutate(formDataToSubmit);
  };

  // console.log("errors === ", methods.formState.errors);

  useEffect(() => {
    if (employee || documents) {
      methods.reset({
        name: employee.name,
        graduationYear: employee.graduation,
        education: employee.education,
        experienceYear: employee.experienceYear,
        experienceMonth: employee.experienceMonth,
        phoneNumber: employee.phoneNumber,
        email: employee.email || "",
        employeeId: employee.employeeId,
        fileNumber: employee.fileNumber,
        designation: employee.designation,
        technology: employee.technology,
        managerId: employee.managerId,
        joiningDate: employee.joiningDate ? dayjs(employee.joiningDate) : null,
        relievingDate: employee.relievingDate
          ? dayjs(employee.relievingDate)
          : null,
        documentLink: employee.documentLink,
        gender: employee.gender,
        dob: employee.dob ? dayjs(employee.dob) : null,
        currentAddress: employee.currentAddress,
        currentCity: employee.currentCity,
        currentState: employee.currentState,
        currentZip: employee.currentZip,
        permanentAddress: employee.permanentAddress,
        permanentCity: employee.permanentCity,
        permanentState: employee.permanentState,
        permanentZip: employee.permanentZip,
        adharCard: employee.adharCard,
        panCard: employee.panCard,
        driverLicense: employee.driverLicense,
        pfAccount: employee.pfAccount,
        esicNo: employee.esicNo,
        ldapUsername: employee.ldapUsername || "",
        fatherName: employee.fatherName,
        motherName: employee.motherName,
        documents: documents?.documentUrl,
        status: employee.employeeStatus,
        permission: employee?.permission ? JSON.parse(employee?.permission) : [],
      });
      setImagePreviewUrl(documents?.documentUrl);
    }
  }, [documents, employee, methods]);

  return (
    <Modal open={openProp} onClose={handleClose}>
      <Box sx={{ ...style }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="row" className={styles.header}>
              <Typography className={styles.heading}>{title}</Typography>
              <Box onClick={() => handleClose()}>
                <CloseIcon className={styles.icon} />
              </Box>
            </Stack>
            <Grid container paddingX={2} paddingY={2} columnSpacing={2}>
              <Grid item xs={12} sm={4}>
                <Grid container rowSpacing={2}>
                  <Grid item width="100%">
                    <Typography className={styles.sectionHeader}>
                      General Info
                    </Typography>
                    <Typography className={styles.label}>
                      Employee Name
                    </Typography>
                    <RHFTextField
                      name="name"
                      sx={overrideTextFieldStyle}
                      disabled={!isAdmin()}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>
                      Graduation Year
                    </Typography>
                    <RHFTextField
                      name="graduationYear"
                      sx={overrideTextFieldStyle}
                      // disabled={!isAdmin()}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>Education</Typography>
                    <RHFTextField
                      name="education"
                      sx={overrideTextFieldStyle}
                      // disabled={!isAdmin()}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>Experience</Typography>
                    <Box display="flex" justifyContent="space-between" gap={2}>
                      <RHFTextField
                        name="experienceYear"
                        placeholder="Year"
                        sx={overrideTextFieldStyle}
                        // disabled={!isAdmin()}
                        // InputProps={{
                        //   endAdornment: (
                        //     <InputAdornment position="end">Year</InputAdornment>
                        //   ),
                        // }}
                      />
                      <RHFTextField
                        name="experienceMonth"
                        placeholder="Month"
                        sx={overrideTextFieldStyle}
                        // disabled={!isAdmin()}
                        // InputProps={{
                        //   endAdornment: (
                        //     <InputAdornment position="end">
                        //       Month
                        //     </InputAdornment>
                        //   ),
                        // }}
                      />
                    </Box>
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>
                      Mobile Number
                    </Typography>
                    <RHFTextField
                      name="phoneNumber"
                      sx={overrideTextFieldStyle}
                      // disabled={!isAdmin()}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>Email</Typography>
                    <RHFTextField
                      name="email"
                      sx={overrideTextFieldStyle}
                      disabled={!isAdmin()}
                    />
                  </Grid>
                </Grid>

                <Grid container rowSpacing={2}>
                  <Grid item width="100%">
                    <Typography className={styles.sectionHeader}>
                      Job Details
                    </Typography>
                    <Typography className={styles.label}>EMS No.</Typography>
                    <RHFTextField
                      name="employeeId"
                      sx={overrideTextFieldStyle}
                      disabled={!isAdmin()}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>File No.</Typography>
                    <RHFTextField
                      name="fileNumber"
                      sx={overrideTextFieldStyle}
                      disabled={!isAdmin()}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>
                      Designation
                    </Typography>
                    <RHFTextField
                      name="designation"
                      sx={overrideTextFieldStyle}
                      disabled={!isAdmin()}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>Technology</Typography>
                    <RHFTextField
                      name="technology"
                      sx={overrideTextFieldStyle}
                      disabled={!isAdmin()}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>Manager</Typography>
                    <RHFSelect name="managerId" disabled={!isAdmin()}>
                      {managersList?.map((ele, idx) => {
                        return (
                          <MenuItem value={ele.value} key={idx}>
                            {ele.label}
                          </MenuItem>
                        );
                      })}
                    </RHFSelect>
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>
                      Date of Joining
                    </Typography>
                    <DatePickerComponent
                      name="joiningDate"
                      isFullWidth
                      disabled={!isAdmin()}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>
                      Relieving Date
                    </Typography>
                    <DatePickerComponent
                      name="relievingDate"
                      isFullWidth
                      disabled={!isAdmin()}
                    />
                  </Grid>
                  {isAdmin() && (
                    <Grid item width="100%">
                      <Typography className={styles.label}>
                        Employee Documents Link
                      </Typography>
                      <RHFTextField
                        name="documentLink"
                        sx={overrideTextFieldStyle}
                        // disabled={!isAdmin()}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Grid container rowSpacing={2}>
                  <Grid item width="100%">
                    <Typography className={styles.sectionHeader}>
                      Additional Info
                    </Typography>
                    <Typography className={styles.label}>Gender</Typography>
                    <RHFSelect
                      name="gender"
                      // disabled={!isAdmin()}
                    >
                      {genderOptions.map((ele, idx) => {
                        return (
                          <MenuItem value={ele.value} key={idx}>
                            {ele.label}
                          </MenuItem>
                        );
                      })}
                    </RHFSelect>
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>
                      Date of Birth
                    </Typography>
                    <DatePickerComponent
                      name="dob"
                      isFullWidth
                      // disabled={!isAdmin()}
                    />
                  </Grid>
                </Grid>

                <Grid container rowSpacing={2}>
                  <Grid item width="100%">
                    <Typography className={styles.sectionHeader}>
                      Current Address
                    </Typography>
                    <Typography className={styles.label}>Address</Typography>
                    <RHFTextField
                      name="currentAddress"
                      sx={overrideTextFieldStyle}
                      // disabled={!isAdmin()}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>City</Typography>
                    <RHFTextField
                      name="currentCity"
                      sx={overrideTextFieldStyle}
                      // disabled={!isAdmin()}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>State</Typography>
                    <RHFTextField
                      name="currentState"
                      sx={overrideTextFieldStyle}
                      // disabled={!isAdmin()}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>Zip</Typography>
                    <RHFTextField
                      name="currentZip"
                      sx={overrideTextFieldStyle}
                      // disabled={!isAdmin()}
                    />
                  </Grid>
                </Grid>

                <Grid container rowSpacing={2}>
                  <Grid item width="100%">
                    <Typography className={styles.sectionHeader}>
                      Permanent Address
                    </Typography>
                    <Typography className={styles.label}>Address</Typography>
                    <RHFTextField
                      name="permanentAddress"
                      sx={overrideTextFieldStyle}
                      // disabled={!isAdmin()}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>City</Typography>
                    <RHFTextField
                      name="permanentCity"
                      sx={overrideTextFieldStyle}
                      // disabled={!isAdmin()}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>State</Typography>
                    <RHFTextField
                      name="permanentState"
                      sx={overrideTextFieldStyle}
                      // disabled={!isAdmin()}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>Zip</Typography>
                    <RHFTextField
                      name="permanentZip"
                      sx={overrideTextFieldStyle}
                      // disabled={!isAdmin()}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Grid container rowSpacing={2}>
                  <Grid item width="100%">
                    <Typography className={styles.sectionHeader}>
                      Identification & Account Information
                    </Typography>
                    <Typography className={styles.label}>Aadhar #</Typography>
                    <RHFTextField
                      name="adharCard"
                      sx={overrideTextFieldStyle}
                      // disabled={!isAdmin()}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>PAN #</Typography>
                    <RHFTextField
                      name="panCard"
                      sx={overrideTextFieldStyle}
                      // disabled={!isAdmin()}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>
                      Driving License #
                    </Typography>
                    <RHFTextField
                      name="driverLicense"
                      sx={overrideTextFieldStyle}
                      // disabled={!isAdmin()}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>
                      PF Account #
                    </Typography>
                    <RHFTextField
                      name="pfAccount"
                      sx={overrideTextFieldStyle}
                      // disabled={!isAdmin()}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>
                      ESIC Number #
                    </Typography>
                    <RHFTextField
                      name="esicNo"
                      sx={overrideTextFieldStyle}
                      // disabled={!isAdmin()}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>
                      LDAP Account
                    </Typography>
                    <RHFTextField
                      name="ldapUsername"
                      sx={overrideTextFieldStyle}
                      // disabled={!isAdmin()}
                    />
                  </Grid>
                </Grid>

                <Grid container rowSpacing={2}>
                  <Grid item width="100%">
                    <Typography className={styles.sectionHeader}>
                      Other Details
                    </Typography>
                    <Typography className={styles.label}>
                      Father's Name
                    </Typography>
                    <RHFTextField
                      name="fatherName"
                      sx={overrideTextFieldStyle}
                      // disabled={!isAdmin()}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>
                      Mother's Name
                    </Typography>
                    <RHFTextField
                      name="motherName"
                      sx={overrideTextFieldStyle}
                      // disabled={!isAdmin()}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>
                      Profile Photo
                    </Typography>
                    <RHFFileUploader
                      name="documents"
                      previewUrl={imagePreviewUrl}
                      // disabled={!isAdmin()}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {isAdmin() && (
                <Grid item xs={12} sm={6}>
                  <Grid container rowSpacing={2}>
                    <Grid item width="100%">
                      <Typography className={styles.sectionHeader}>
                        Status
                      </Typography>
                      <RHFRadioGroup
                        name="status"
                        options={statusOptions}
                        row={true}
                        // disabled={!isAdmin()}
                      />
                    </Grid>
                    <Grid item width="100%">
                      <Typography className={styles.sectionHeader}>
                        Permissions
                      </Typography>

                      <RHFMultiCheckbox
                        name="permission"
                        options={permissionsOptions}
                        row={true}
                        // disabled={!isAdmin()}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>

            {/* {isAdmin() && ( */}
            <Stack className={styles.btnCont}>
              <Box display="flex" justifyContent="flex-end">
                <LoadingButton
                  type="submit"
                  variant="contained"
                  className={styles.btn}
                  loading={isPending || isUpdating}
                  loadingIndicator={<Loader size={20} sx={{ color: "white" }} />}
                >
                  Submit
                </LoadingButton>
              </Box>
            </Stack>
            {/* )} */}
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
}
