import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import TableHeader from "../../../components/TableHeader/TableHeader";
import styles from "./styles.module.css";
import DataGridComp from "../../../components/DataGrid/DataGrid";
import viewIcon from "../../../styles/icons/viewicon.svg";
import deleteIcon from "../../../styles/svg/deleteIcon.svg";
import { PolicyListQuery } from "../../../utils/reactQuery/policy/PolicyList";
import NotesPopup from "../../../components/NotesPopup/NotesPopup";
import { useResponsive } from "../../../hooks/customUseResponsive";
import { useMutation } from "@tanstack/react-query";
import { usDateFormate } from "../../../hooks/dateFormat";
import { postReadPolicy } from "../../../services/policy/apiReadPolicy";
import AddPolicyModal from "../../ManageTeam/AdminPolicies/AddPolicy";
import ConfirmModal from "../../../components/ConfirmationModal/ConfirmModal";
import { deletePolicyNotice as deletePolicyApi } from "../../../services/notice/apiDeletePolicyNotice";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import Loader from "../../../components/Loader";

export default function Policies() {
  const { policyList, isLoading, refetch } = PolicyListQuery();
  const [notePopup, setNotePopup] = useState(false);
  const [policyId, setPolicyId] = useState("");
  const [addPolicy, setAddPolicy] = useState(false);
  const [deletePolicy, setDeletePolicy] = useState(false);
  const isMobile = useResponsive("down", "sm");
  const location = useLocation();
  const isDashboard = location.pathname.includes("dashboard");

  // console.log("policyId", policyId);

  const { mutate } = useMutation({
    mutationFn: postReadPolicy,
    onSuccess: (data) => {
      refetch();
    },
    onError: (err) => {
      console.error("Error while updating the read status", err);
    },
  });

  const { mutate: deleteMutation } = useMutation({
    mutationFn: deletePolicyApi,
    onSuccess: (data) => {
      toast.success(data.data.message);
      refetch();
    },
    onError: (err) => {
      console.error("Error while deleting the polcy", err);
      toast.error("Something went wrong while deleting the policy.");
    },
    onSettled: () => {
      setDeletePolicy(false);
    },
  });

  const handleView = (id) => {
    setPolicyId(id);
    setNotePopup(true);
    mutate({ id });
  };

  const handleDelete = (id) => {
    setPolicyId(id);
    setDeletePolicy(true);
    // console.log("handleDelete policyId", policyId);
  };

  const handleConfirm = (id) => {
    // console.log("confirm delete id", id);
    deleteMutation({ id });
  };

  const columns = [
    {
      field: "col1",
      headerName: "Subject",
      minWidth: isMobile ? 130 : 250,
      flex: 2,
      sortable: false,
      resizable: false,
      renderCell: (value) => {
        return (
          <Stack direction="row" className={styles.noticeSubject}>
            <Typography className={styles.title}>
              {value.formattedValue.name}
            </Typography>
            {value.formattedValue.isRead ? null : (
              <Box className={styles.greenBadge}></Box>
            )}
          </Stack>
        );
      },
    },
    {
      field: "col2",
      headerName: "Create Date",
      minWidth: isMobile ? 100 : 140,
      flex: 1.1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{usDateFormate(value.value)}</span>,
    },
    {
      field: "col3",
      headerName: "Actions",
      minWidth: isMobile ? 100 : 123,
      sortable: false,
      flex: 1,
      resizable: false,
      renderCell: (value) => {
        return (
          <Stack className={styles.btnCont} gap={1}>
            <Box
              component="img"
              src={viewIcon}
              alt="view"
              className={[styles.viewBtn, styles.icon]}
              onClick={() => handleView(value.value)}
            />

            {!isDashboard && (
              <Box
                component="img"
                src={deleteIcon}
                alt="delete"
                className={[styles.viewBtn, styles.icon]}
                onClick={() => handleDelete(value.value)}
              />
            )}
          </Stack>
        );
      },
    },
  ];

  const initialRows = policyList
    ? policyList.map((ele, index) => {
        return {
          id: index,
          col1: { name: ele.policy_name, isRead: ele.is_read_by_employee },
          col2: ele.date_added,
          col3: ele.policy_id,
        };
      })
    : [];

  return (
    <Box className={styles.mainContainer}>
      {/* For the scrolling of the sections */}
      <span id="policies" className={styles.policyContainer}></span>
      <TableHeader
        heading="Policies"
        btnicon={!isDashboard && "ic:outline-plus"}
        handleModal={!isDashboard && (() => setAddPolicy(true))}
      />
      <Box className={styles.datagridCont}>
        {isLoading && (
          <Stack
            className={
              isDashboard ? styles.dashboardLoaderCont : styles.loaderCont
            }
          >
            <Loader />
          </Stack>
        )}
        <DataGridComp columns={columns} initialRows={initialRows} />
      </Box>
      {notePopup && (
        <NotesPopup
          openPop={notePopup}
          handleClose={() => setNotePopup(false)}
          id={policyId}
          policy={true}
        />
      )}
      {addPolicy && (
        <AddPolicyModal
          openProp={addPolicy}
          handleClose={() => setAddPolicy(false)}
          refetch={refetch}
        />
      )}
      {deletePolicy && (
        <ConfirmModal
          openProp={deletePolicy}
          handleClose={() => setDeletePolicy(false)}
          handleConfirm={() => handleConfirm(policyId)}
          tabHeader="Delete Policy"
          heading="Delete Policy ?"
          content="Are you sure you want to delete this policy? This action can not be undone."
          btnText="Delete"
        />
      )}
    </Box>
  );
}
