import { useQuery } from "@tanstack/react-query";
import apiShowCompOffList from "../../../../services/manager/compOff/apiShowCompOffList";
import { usDateFormate } from "../../../../hooks/dateFormat";
import { formatCompOffType } from "../../../helpers";

export default function GetCompOffDateQuery(id) {
  // console.log("GetCompOffDateQuery id", id);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["GetCompOffDate", id],
    queryFn: () => apiShowCompOffList({ employee_id: id }),
    enabled: !!id,
  });

  // console.log("GetCompOffDate data", data?.data);

  const availableCompOffs = data?.data?.compOffList
    .filter((item) => !item.is_adjusted)
    .map((compoff) => ({
      id: compoff.compoff_id,
      date: usDateFormate(compoff.compoff_date),
      day: compoff.day_type.slice(0, 3),
      type: formatCompOffType(compoff.compoff_type),
    }));

  return { availableCompOffs, isLoading, refetch };
}
