import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useResponsive } from "../../../../hooks/customUseResponsive";
import styles from "./styles.module.css";
import DataGridComp from "../../../../components/DataGrid/DataGrid";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../../../components/Loader";
import ConfirmModal from "../../../../components/ConfirmationModal/ConfirmModal";
import RejectRequestModal from "../../../../components/RejectRequestModal";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { postApproveLeaveRequest } from "../../../../services/manager/leaveRequest/apiApprovedLeaveRequest";
import { postRejectLeaveRequest } from "../../../../services/manager/leaveRequest/apiRejectleaveRequest";
import toast from "react-hot-toast";
import rejectIcon from "../../../../styles/icons/crossIcon.svg";
import approvedIcon from "../../../../styles/icons/approvedIcon.svg";
import viewIcon from "../../../../styles/icons/viewicon.svg";
import ManagerNote from "../../../../components/managerNote/ManagerNote";
import { isAdmin } from "../../../../utils/helpers";
import LeaveApprovalListQuery from "../../../../utils/reactQuery/manager/leaveRequest/LeaveApprovalList";

export default function LeaveApprovalModal({ open, handleClose }) {
  const isMobile = useResponsive("down", "sm");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "330px" : 1200,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
  };

  const { leaveApprovalList, isLoading, refetch } = LeaveApprovalListQuery();

  const [approveModal, setApproveModal] = useState(false);
  const [rejectRequestModal, setRejectRequestModal] = useState(false);
  const [notePopover, setNotePopover] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const { mutate } = useMutation({
    mutationFn: postApproveLeaveRequest,
    onSuccess: (data) => {
      refetch();
      toast.success(data.data.message);
      setApproveModal(false);
    },
    onError: (data) => {
      console.error("error employee feedback", data);
      toast.error(data.response.data.error);
    },
    onSettled: () => {
      setApproveModal(false);
    },
  });

  const { mutate: mutateRejectRequest, isPending } = useMutation({
    mutationFn: postRejectLeaveRequest,
    onSuccess: (data) => {
      refetch();
      toast.success(data.data.message);
      setApproveModal(false);
    },
    onError: (data) => {
      console.error("error employee feedback", data);
    },
    onSettled: () => {
      setRejectRequestModal(false);
    },
  });

  const handleView = (id) => {
    setNotePopover(true);
    setSelectedId(id);
  };

  const handleApproveApplication = (id) => {
    setSelectedId(id);
    setApproveModal(true);
  };

  const confirmApproveApplication = () => {
    mutate(selectedId);
  };

  const handleRejectApplication = (id) => {
    setSelectedId(id);
    setRejectRequestModal(true);
  };

  const handleRejectRequest = (reason) => {
    mutateRejectRequest({ id: selectedId, reason });
  };

  const columns = [
    {
      field: "col1",
      headerName: "Employee Name",
      minWidth: 127,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col2",
      headerName: "Date",
      minWidth: 127,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col3",
      headerName: "Total Days",
      minWidth: 126,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col4",
      headerName: "Leave Type",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col5",
      headerName: "Applied On",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col6",
      headerName: "Status",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => (
        <Stack className={styles.statusCont}>
          <Typography
            className={
              value.value === "Approved"
                ? styles.statusApproved
                : styles.statusPending
            }
          >
            {value.value}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "col7",
      headerName: "Actions",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => (
        <Stack className={styles.iconsCont}>
          <img
            className={styles.icon}
            src={viewIcon}
            onClick={() => handleView(value.value.id)}
            alt="view"
          />
          {value.value.status === "Pending" ? (
            <>
              <img
                className={styles.icon}
                src={approvedIcon}
                onClick={() => handleApproveApplication(value.value.id)}
                alt="approve"
              />
              <img
                className={styles.icon}
                src={rejectIcon}
                onClick={() => handleRejectApplication(value.value.id)}
                alt="reject"
              />
            </>
          ) : value.value.status === "Rejected" ? (
            <img
              className={styles.icon}
              src={approvedIcon}
              onClick={() => handleApproveApplication(value.value.id)}
              alt="approve"
            />
          ) : value.value.status === "Proceed" && isAdmin() ? (
            <>
              <img
                className={styles.icon}
                src={approvedIcon}
                onClick={() => handleApproveApplication(value.value.id)}
                alt="approve"
              />
              <img
                className={styles.icon}
                src={rejectIcon}
                onClick={() => handleRejectApplication(value.value.id)}
                alt="reject"
              />
            </>
          ) : (
            <img
              className={styles.icon}
              src={rejectIcon}
              onClick={() => handleRejectApplication(value.value.id)}
              alt="reject"
            />
          )}
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...style }}>
          <Stack direction="row" className={styles.headerContainer}>
            <Typography className={styles.tableHead}>Leave Approval</Typography>
            <CloseIcon
              sx={{ color: "#fff", cursor: "pointer" }}
              onClick={handleClose}
            />
          </Stack>
          <Box className={styles.bodyCont}>
            <Box className={styles.datagridCont}>
              {isLoading && (
                <Stack className={styles.loaderCont}>
                  <Loader />
                </Stack>
              )}

              <DataGridComp
                columns={columns}
                initialRows={leaveApprovalList || []}
                pageSize={7}
              />
            </Box>
          </Box>
        </Box>
      </Modal>

      {notePopover && (
        <ManagerNote
          openPop={notePopover}
          handleClose={() => setNotePopover(false)}
          id={selectedId}
        />
      )}
      {approveModal && (
        <ConfirmModal
          openProp={approveModal}
          handleClose={() => setApproveModal(false)}
          handleConfirm={() => confirmApproveApplication()}
          tabHeader="Approve Request"
          content="Do you want to proceed with approving this request?"
          btnText="approve"
        />
      )}
      {rejectRequestModal && (
        <RejectRequestModal
          openProp={rejectRequestModal}
          handleClose={() => setRejectRequestModal(false)}
          handleConfirm={handleRejectRequest}
          isPending={isPending}
        />
      )}
    </>
  );
}
