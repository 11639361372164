import Box from "@mui/material/Box";
import TableHeader from "../../../components/TableHeader/TableHeader";
import useEmployeeSelection from "../../../hooks/useEmployeeSelection";
import InTime from "../../../components/InTimeReport/InTimeReport";
import styles from "./styles.module.css";

export default function InTimeReport() {
  const { currentEmployee, emplist, onCurrentEmployeeChange } =
    useEmployeeSelection();

  return (
    <Box className={styles.mainContainer}>
      <TableHeader
        heading="In Time Report"
        emplist={emplist}
        handleChange={onCurrentEmployeeChange}
        currentEmployee={currentEmployee.employee_id}
      />
      <InTime currentEmployeeId={currentEmployee.employee_id} />
    </Box>
  );
}
