import * as Yup from "yup";

const AddAttendanceSchema = Yup.object().shape({
  employee_id: Yup.string(),
  start_date: Yup.date().required("Please enter start date."),
  reason: Yup.string().required("Please enter message."),
  application_type: Yup.string().required("Type is required"),
  in_time: Yup.string().when("application_type", {
    is: (value) => value === "in" || value === "in-out",
    then: (schema) => schema.required("Please enter in time."),
    otherwise: (schema) => schema.nullable(),
  }),
  out_time: Yup.string().when("application_type", {
    is: (value) => value === "out" || value === "in-out",
    then: (schema) => schema.required("Please enter out time."),
    otherwise: (schema) => schema.nullable(),
  }),
});

export default AddAttendanceSchema;
